$title-line-height: 21px;
$title-description-line-height: 16px;
$title-description-margin-top: 5px;
$title-description-margin-bottom: 20px;

@mixin title {
  &__title {
    color: $color--txt-secondary;
    font-size: 18px;
    font-weight: $font-weight-medium;
    line-height: $title-line-height;

    &-description {
      font-size: 12px;
      font-weight: $font-weight-regular;
      line-height: $title-description-line-height;
      margin: $title-description-margin-top 0 $title-description-margin-bottom 0;
    }
  }
}
