// stylelint-disable
@import '../mixins';

button {
  appearance: none;
  font-family: $font-family-primary-proportional;
}

.btn {
  @include transition();
  @include user-select();
  align-items: center;
  border: 0;
  border-radius: 3px;
  color: $color-text-button;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  font-weight: $font-weight-semibold;
  line-height: 21px;
  max-height: 32px;
  padding: 7px 12px;
  pointer-events: auto;

  &:hover {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.125);
  }

  &.mod--btn--disabled {
    background-image: none;
    box-shadow: none;
    color: $color--white;
    cursor: not-allowed;
    text-shadow: none;
  }
}

.btn-default,
.btn-danger,
.btn-success {
  background-color: $color--btn-primary--bg;

  &:hover,
  &.active {
    background-color: $color--btn-primary--hover;
  }
  //TODO: remove this selector once all other instances are migrated to mod--btn--active

  &.mod--btn--active {
    // probably can remove
    background: scale_color(
      $color-btn-neutral,
      $saturation: 20%,
      $lightness: -10%
    );
  }

  &.mod--btn--disabled {
    background-color: $color--btn-disabled--bg;
  }

  &.mod--btn--complete {
    background-color: lighten($color-btn-neutral, 20%);
  }

  &.mod--btn--pending {
    @include animation-bg-darken-pulse(
      $color-btn-neutral,
      'kf-ExUi_btn--primary-pending'
    );
    cursor: wait;
  }
}

.btn-secondary {
  background-color: $color--btn-secondary--bg;
  color: $color--btn-secondary--text;

  &:hover {
    border: 1px solid $color--btn-secondary--border-hover;
  }

  &:active {
    background-image: none;
    border: 1px solid $color--btn-secondary--border-hover;
  }

  &.mod--btn--active {
    background: scale_color(
      $color-btn-secondary,
      $saturation: 20%,
      $lightness: -10%
    );
  }

  &.mod--btn--disabled {
    background-color: $color-btn-disabled;
  }

  &.mod--btn--complete {
    background-color: lighten($color-btn-secondary, 20%);
  }

  &.mod--btn--pending {
    @include animation-bg-darken-pulse-light(
      $color-btn-secondary,
      'kf-btn-secondary-pending'
    );
    cursor: wait;
  }
}

.btn--subtle {
  background-color: transparent;
  border: 1px solid transparent;
  color: $color--text-link;
  font-weight: $font-weight-semibold;

  &:hover {
    background-color: hsla(209, 100%, 100%, 0.05);
    box-shadow: 0 0 4px 2px hsla(210, 95%, 55%, 0.25);
  }

  &.mod--no-border {
    border: none;

    &:hover {
      background-color: transparent;
      box-shadow: none;
    }
  }
}

.btn-small {
  font-size: pxToRem(11);
  line-height: pxToRem(12);
  padding: 8px 9px 6px;
}

.btn-large {
  padding: 12px 14px;
}

.btn-with-link {
  color: $color--text-link;
}

.btn-with-icon {
  padding: 3px 12px 7px;

  &.mod--icon--right .icn {
    margin-left: 5px;
  }

  &.mod--icon--left .icn {
    margin-right: 5px;
  }

  &.btn-large {
    //When we add large buttons with icons TODO: add correct padding for large button icon
  }
}

.btn-toggle {
  @extend .btn;
  @extend .btn-default;
  border-radius: 0;
  margin: 1px 0 5px 5px;
}

@media (max-height: 800px) {
  .btn-toggle {
    padding: 6px 8px;
  }
}

.state-btns {
  .btn-toggle {
    border-radius: 0;
    margin: 0;
    padding: 4px 8px;

    &:first-child {
      border-bottom-left-radius: 16px;
      border-top-left-radius: 16px;
    }

    &:last-child {
      border-bottom-right-radius: 16px;
      border-top-right-radius: 16px;
    }
  }
}
