@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_NetworksMapCountMenu {
  width: 150px;
  height: 27px;
  color: $color--blue-70;
  border-radius: 5px;
  margin: 25px !important;
  background: $color--gray-30;
  font-family: $font-family-primary-proportional;
  overflow: hidden;

  .ExUi_NetworksMapCountMenu__menuEntry {
    font-size: 11px;
    font-weight: 600;
    display: inline-flex;
    width: 50%;
    height: 100%;
    text-align: center;

    .ExUi_NetworksMapCountMenu_menuTitle {
      margin: auto;

      .ExUi_NetworksMapCountMenu_menuTitleCheck {
        // unable to use Icon component since Mapbox Control uses plain javascript
        background-image: url('./select.svg');
        height: 7px;
        width: 8px;
        display: inline-block;
        margin-right: 1px;
      }
    }
  }

  .ExUi_NetworksMapCountMenu__menuEntry:hover {
    cursor: pointer;
  }

  .ExUi_NetworksMapCountMenu_menuTitle--selected {
    background: $color--blue-70;
    color: $color--white;
  }
}
