@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
//stylelint-disable
$thead-th-height: pxToRem(16);
$thead-th-padding-tb: $layout-component-spacing-medium;
$rt-th-line-height: 21px;
$rt-th-vert-padding: 9px;

.cn--base-table {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100vw;

  .cn--table {
    @include scroll();

    height: 100%;
    overflow: auto;
    position: relative;

    &::-webkit-scrollbar-track {
      border-top: (($component-spacing-xxs * 2) + $rt-th-line-height) solid #fff;
    }

    &::-webkit-scrollbar-track-piece:start {
      margin-top: (($component-spacing-xxs * 2) + $rt-th-line-height);
    }

    &::-webkit-scrollbar-track-piece {
      margin-top: (($component-spacing-xxs * 2) + $rt-th-line-height);
    }
  }

  table {
    border-collapse: collapse;
    min-width: 100%;

    tbody {
      &.has-no-data {
        display: block;
        min-height: 5rem;
      }

      tr {
        &.focused {
          background-color: $color--table--row--bg--active;
        }

        &.no-data-row {
          display: block;

          td {
            display: block;
          }
        }

        &:hover,
        &.hover {
          background-color: $color--table--row--bg--hover;
          cursor: pointer;

          &:active,
          &.active {
            background-color: $color--table--row--bg--active;
          }
        }

        &.selected-odd {
          background: $color--table--row--bg--active;
        }

        &.selected-even {
          background: $color--table--row--bg--active;
        }

        &.show-sub-row {
          border-bottom: none;
          padding-bottom: 9px;
          vertical-align: top;
        }
      }
    }

    th,
    td {
      &.cn--select-checkbox {
        padding: 0;
        padding-left: $thead-th-padding-tb;
      }

      &.subCell {
        padding-top: 0;
      }
    }

    thead {
      .resize-divider {
        display: none;
      }

      &:hover {
        .resize-divider {
          display: block;
        }
      }

      th {
        .sortable {
          cursor: pointer;

          &:hover {
            color: $color--text-link;

            + .cn--sort-arrows {
              .mod--dir--asc {
                @include sprite-alt(750, 60);
              }

              .mod--dir--dsc {
                @include sprite-alt(780, 60);
              }
            }
          }
        }
      }

      tr th {
        text-align: left;
        background-color: $color--white;
        font-weight: $font-weight-semibold;
        height: $thead-th-height;
        padding-bottom: $thead-th-padding-tb;
        padding-top: $thead-th-padding-tb;
        position: sticky;
        top: 0;
        z-index: 3;
        box-shadow: inset 0 0px 0 $color-border-bottom-table-secondary, inset 0 -2px 0 $color-border-bottom-table-secondary;

        &:hover {
          z-index: 5; // to fix overlay issue with child tooltips
        }

        .cn--tooltip {
          z-index: 3;
        }

        .resize-divider {
          background-color: $color-border-bottom-table-secondary;
          cursor: ew-resize;
          height: 100%;
          position: absolute;
          right: 1px;
          top: 0;
          width: 1px;

          &:hover {
            right: 0;
            width: 3px;
          }
        }
      }
    }

    .rt-td,
    .rt-th {
      color: $color-text-table;
      font-size: 14px;
      font-weight: $font-weight-regular;
      line-height: $rt-th-line-height;
      padding: 8px 16px;
      text-overflow: ellipsis;
      transition: 0.3s ease;
      transition-property: width, min-width, padding, opacity;
      vertical-align: middle;
      white-space: pre-line;

      &:first-child {
        padding-left: $layout-component-spacing-medium;
      }

      &.-hidden {
        border: 0 !important;
        min-width: 0 !important;
        opacity: 0 !important;
        padding: 0 !important;
        width: 0 !important;
      }

      &.-sort-asc {
        .cn--sort-arrows {
          .icn--sort-col-header.mod--dir--dsc {
            display: none;
          }
        }
      }

      &.-sort-desc {
        .cn--sort-arrows {
          .icn--sort-col-header.mod--dir--asc {
            display: none;
          }
        }
      }
    }

    .rt-th {
      font-weight: $font-weight-bold;
      line-height: $rt-th-line-height;
      max-width: 130px;
      overflow: hidden;
      padding: $rt-th-vert-padding 16px;
      text-overflow: ellipsis;
      white-space: nowrap;

      .cn--sort-arrows {
        cursor: pointer;
        display: inline-block;
        margin-top: -2px;
        position: relative;
        vertical-align: middle;

        .mod--dir--asc,
        .mod--dir--dsc {
          display: block;
          margin: -3px 3px;

          &:hover {
            color: $color--text-link;
          }
        }

        .mod--dir--asc {
          &:hover {
            @include sprite-alt(750, 60);
          }
        }

        .mod--dir--dsc {
          &:hover {
            @include sprite-alt(780, 60);
          }
        }
      }
    }

    .rt-tr {
      border-bottom: 1px solid $color-border-bottom-table-secondary;
      text-align: left;
    }

    .rt-noData {
      background: rgba(255, 255, 255, 0.8);
      color: rgba(0, 0, 0, 0.5);
      display: block;
      left: 50%;
      margin-top: $component-spacing-xl;
      padding: $component-spacing-m;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease;
      z-index: 1;
    }
  }

  .loading {
    background: rgba(255, 255, 255, 0.8);
    bottom: 0;
    display: block;
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    text-align: center;
    top: 0;
    transition: all 0.3s ease;
    width: 100vw;
    z-index: 1000;

    > div {
      color: rgba(0, 0, 0, 0.6);
      display: block;
      font-size: 15px;
      left: 0;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateY(-52%);
      transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      width: 100%;
    }
  }
}

.ExUi_BaseTable__banner {
  padding: 0 $layout-component-spacing-medium $layout-component-spacing-x-small;
}
