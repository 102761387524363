@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
$line-height: pxToRem(21);

.ExUi_ellipsis-data-cell {
  line-height: $line-height;
  position: relative;

  .placeholder {
    height: #{$line-height * 2.85};
    overflow: hidden;
  }

  .ellipsis-tooltip {
    bottom: pxToRem(2);
    height: pxToRem(13); // match font size
    margin: 0;
    position: absolute;
    right: 0;

    .cn--ellipsis {
      left: -2px;
      letter-spacing: -4px;
      position: relative;
    }

    .cn--tooltip {
      line-height: 1.4;
      z-index: 99;

      .content--tooltip {
        max-width: pxToRem(650);
      }
    }
  }
}
