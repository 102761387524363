@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
/* stylelint-disable */
$size--kv--label-width: 200px;

.cn--evidence {
  .evidence-list {
    list-style: none;
    margin: 0 0 $size--padding--module--interior 0;
    padding: 0;

    li {
      .label {
        width: $size--kv--label-width;

        .label-with-info {
          display: flex;
          justify-content: flex-end;
        }

        .label-info-icn {
          margin-right: $component-spacing-min;
        }
      }

      .value {
        .multiline-monospaced {
          background: hsla(221, 20%, 97%, 1);
        }

        &--vcentered {
          align-items: center;
          display: inline-flex !important;
        }

        &__icon {
          margin: 0 4px 0 0;
        }

        .null-value {
          color: $color--kv--value--text--muted;
          font-weight: $font-weight-regular;
        }

        .cn--json-row {
          display: inline-block;

          pre {
            font-size: 12px;
            margin: 2px 0 0;
          }
        }

        .cn--cert-summary {
          margin-bottom: 10px;
          margin-top: 3px;

          .data-list {
            margin-bottom: 10px;
          }

          .data-list-item-key {
            width: 160px;
          }

          .data-list-item-value {
            width: calc(100% - 170px);
            word-wrap: break-word;
          }
        }

        .discovery-type {
          flex-direction: column;

          &__details {
            color: $color-discovery-type-details-text;
            font-size: 12px;
            font-weight: $font-weight-regular;
            margin-top: 4px;
            max-width: 540px;
          }

          &__colocated-details {
            list-style-type: disc;
            padding-left: 15px;

            li {
              display: list-item;
            }
          }
        }
      }
    }

    .mini-table {
      padding: 10px 0;
    }
  }
}

.ExUi_mini-table {
  display: inline-block;
  max-width: 100%;
  overflow-x: hidden;
  width: 780px;

  table {
    border-spacing: 0;
    max-width: 8px * 85;
    min-width: 8px * 70;

    thead {
      background-color: $color--kv--header--text;

      td {
        border-left: 1px solid hsla(220, 10%, 90%, 1);
        color: $color--kv--value--text;
        font-size: 11px;
        font-weight: $font-weight-regular;
        line-height: 1.1em;
        padding: 4px 8px 2px;
        text-align: left;
        vertical-align: bottom;

        &:nth-of-type(1) {
          border-left: none;
        }
      }
    }

    tbody tr td {
      border-bottom: 1px solid hsla(220, 10%, 90%, 1);
      font-size: 13px;
      font-weight: $font-weight-regular;
      padding: 4px 8px;

      &:first-child {
        word-break: normal;
      }
    }
  }
}

.multiline-monospaced {
  border-radius: $radius--panel;
  display: inline-block;
  padding: 0 4px;

  .line {
    display: block;
    font-family: $font-family-primary-mono;
    font-size: 12px;
    padding: 1px 0;
  }
}

.key-value-evidence {
  &__list-item {
    display: flex;
    margin: 8px 0;
    min-height: 24px;
  }
  &__label {
    color: $color--kv--label--text;
    display: flex;
    font-size: 12px;
    font-weight: $font-weight-regular;
    justify-content: flex-end;
    line-height: 16px;
    min-width: 200px;
    padding-right: 16px;
    padding-top: 2px;
    text-align: right;
    vertical-align: top;
  }

  &__value {
    color: $color--kv--value--text;
    display: flex;
    font-size: 14px;
    line-height: 16px;
    padding-top: 4px;
    word-break: break-word;
  }
}
