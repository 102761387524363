@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
/**
  This stylesheet is copied from react-table, and modified to interact both with react-table's preassigned classes,
  as well as assign custom classes/styles. Many classes are unused to due not needing the functionality that they style;
  they are left in this stylesheet, with their default styling commented out, to enable future development if necessary

  See: https://github.com/react-tools/react-table/blob/master/src/index.styl

  NOTE: "Expander" here is a term used by react-table to refers to the component which can be clicked to expand each row
 */

$row-padding-left: pxToRem(30);
$cell-padding: pxToRem(5);

.issues-table {
  height: 100%;

  &.no-horizontal-filter-bar {
    height: 100%;
  }

  .cn--base-table {
    .header-tooltip {
      // Aligns tooltip with text, instead of middle of entire header container
      width: 10px;
    }
  }

  .bulk-edit-tooltip {
  }
}

.bulk-edit-dropdown {
  &__footer {
    background: white;
    border-top: solid hsla(212, 17%, 83%, 1) 1px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }
}
