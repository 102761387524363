@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_ {
  &TenantSwitcher {
    &__errorToast {
      // stylelint-disable-next-line
      .Toastify__close-button {
        align-self: flex-start;
      }

      &Link {
        color: rgb(40, 87, 170);
        text-decoration: none;
      }

      &Message {
        font-family: Roboto;
        font-size: 12px;
      }

      &Title {
        color: $color--gray-90;
        font-family: Roboto;
        font-size: 14px;
        font-weight: $font-weight-medium;
        margin-bottom: 10px;
      }
    }

    &__loader {
      background: rgba(255, 255, 255, 0.8);
      bottom: 0;
      display: block;
      height: 100vh;
      left: 0;
      position: fixed;
      right: 0;
      text-align: center;
      top: 0;
      transition: all 0.3s ease;
      width: 100vw;
      z-index: 99999;

      &Text {
        color: rgba(0, 0, 0, 0.6);
        display: block;
        font-size: 15px;
        left: 0;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translateY(-52%);
        transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        width: 100%;
      }
    }
  }
}
