@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
div {
  &.dashboard-list-link {
    & {
      margin-top: 20px;
      min-height: 20px;
      text-align: right;
      position: relative;
    }

    &--absolute-positioning {
      min-height: 20px;
      text-align: right;
      position: absolute;
      bottom: 20px;
      right: 50px;
    }
  }
}

b {
  &.dashboard-graph--list-link-blue {
    font-weight: $font-weight-medium;
    font-size: pxToRem(13);
    margin-right: 2px;
    color: hsla(214, 82%, 38%, 1);

    &:hover {
      color: hsla(214, 82%, 28%, 1);
    }
  }

  &.dashboard-graph--list-link-light-blue {
    @extend .dashboard-graph--list-link-blue;
    color: hsla(209, 100%, 89%, 1);

    &:hover {
      color: hsla(209, 100%, 79%, 1);
    }
  }
}

span {
  &.dashboard-list-link--svg {
    vertical-align: bottom;
    position: absolute;
    bottom: 2px;
  }
}
