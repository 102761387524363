@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.services-table {
  height: 100%;

  .cn--base-table {
    table {
      tbody {
        tr {
          height: 72px;
          vertical-align: top;

          .rt-td {
            vertical-align: top;
          }

          &.sub-row {
            height: 25px;
          }

          &.show-sub-row {
            height: 47px;
          }
        }
      }
    }
  }

  .tag {
    margin-right: 14px;
    line-height: 14px;
    font-size: 11px;
    color: $color-text-sub-row-tags;

    &:last-child {
      margin-right: 0;
    }
  }

  .cve-count-column {
    display: inline-flex;
    width: 120px;
  }

  .cve-confidence-column {
    display: inline-flex;
  }

  .cve-confidence-target-cve {
    margin-left: 2px;
    width: max-content;
  }

  .cve-confidence-na {
    color: $color--gray-70;
  }
}
