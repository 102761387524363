// stylelint-disable
input[type='text'],
input[type='number'],
input[type='date'],
input[type='time'],
input[type='password'],
input[type='search'],
div[contenteditable],
textarea,
select,
input.nc-input {
  background-color: $color--input-background;
  border: $input-border-width solid $color-form-input-border;
  box-sizing: border-box;
  color: $color-form-text-input;
  font-family: $font-family-primary-proportional;
  font-size: 100%;
  min-width: 164px;
  padding: calc(0.5rem - #{$input-border-width})
    calc(1rem - #{$input-border-width});

  &:focus {
    border-color: $color-form-input-border-focus;
    outline: none;
  }

  &.mod--frm--has-error {
    border-color: $color-text-error;
    box-shadow: inset scale_color($color-text-error, $lightness: 30%) 0 0 2px;
  }

  &::placeholder {
    color: $color--txt-placeholder;
  }
}

input,
textarea {
  padding: 0.5rem;
}

textarea {
  min-height: 88px;
}

$checkbox-size: 15;

input[type='checkbox'] {
  appearance: none;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px $color--gray-30;
  cursor: pointer;
  height: calc($checkbox-size/$size-font-root) * 1rem;
  width: calc($checkbox-size/$size-font-root) * 1rem;

  &:checked,
  &:indeterminate {
    @include sprite-background();
    background-color: $color-checkbox-bg-selected;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 1px $color-interaction;
    box-sizing: border-box;
    overflow: hidden;
  }

  &:checked {
    @include sprite(
      $checkbox-size,
      $checkbox-size,
      210 - calc(calc($checkbox-size - 9) / 2),
      270 - calc(calc($checkbox-size - 7) / 2)
    );
  }

  &:indeterminate {
    @include sprite(
      $checkbox-size,
      $checkbox-size,
      240 - calc(calc($checkbox-size - 9) / 2),
      270 - calc(calc($checkbox-size - 3) / 2)
    );
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.boolean-toggle {
    @include sprite-background();
    @include sprite(26, 14, 72, 90);
    @include transition();
    background-color: $color-info-null;
    border-radius: 0.5rem;
    box-shadow: none;
    height: calc(14 / $size-font-root) * 1rem;
    margin: 0 0.5rem -2px;
    width: calc(26 / $size-font-root) * 1rem;

    &:checked {
      @include sprite-alt(60, 90);
      background-color: $color-success;
    }
  }
}

.error {
  background-color: hsl(0, 0%, 100%);
  border: 1px solid $color--input-error;
  border-left: 3px solid $color--input-error;
  color: $color--input-error;
  font-size: pxToRem(12);
  font-weight: $font-weight-regular;
  margin: 5px 5px 8px;
  padding: 6px;
}
