@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.expander-tabs {
  background: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.expander-tab-list {
  background: $color-nav-menu-bar-bg-2;
  margin: 0;
  padding-left: 12px;
  flex: 0 1 auto;
  display: inline-flex;
  flex-flow: row;
  box-shadow: inset 0 -1px 0 $color--border--panel;
}

.expander-tab {
  background: transparent;
  display: flex;
  flex: 0 1 auto;
  margin-right: 10px;
  height: 40px;
  padding: 12px 8px 10px 8px;
  box-sizing: border-box;
  color: $color-sub-nav-text-link;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: $color-sub-nav-text-link-hover;
    border-bottom: 3px solid $color-sub-nav-border-bottom-hover;
  }

  &.mod--active {
    color: $color-sub-nav-text-link-active;
    font-weight: $font-weight-medium;
    border-bottom: 3px solid $color-sub-nav-border-bottom-active;
    z-index: 1;
  }
}

.expander-tab-panel {
  background: $color-bg-body;
  width: 100%;
  flex: 1 1 auto;
  display: block;
  position: relative;

  .expander-tab-panel-content {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}
