@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
@import '~leaflet/dist/leaflet.css';
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
@import 'client/app/globals/layout.scss';
@import 'client/app/globals/modals/modals.scss';
@import 'client/app/globals/components/expanderVersionString/expanderVersionString.scss';

/**
 * App-wide Styles
 */

.browsehappy {
  margin: 0.2em 0;
  background: #fff;
  color: #333;
  padding: 0.2em 0;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  .container {
    max-width: 730px;
  }
}

/* Rules for sizing the material design icons */
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}

.cn--expander {
  expander {
    display: flex;
    flex-direction: column;
    height: 100vh;

    > ui-view {
      flex-grow: 1;
      overflow: auto;
    }
  }
}

// tags column
.tags-columns-content {
  text-overflow: ellipsis;
  white-space: nowrap;
}
