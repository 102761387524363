@mixin draggable-container {
  .ExUi_drag-column {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 4px 10px;
    padding: 8px 10px;

    &__icon {
      border-bottom: 2px solid $color-drag-icon-border;
      border-top: 2px solid $color-drag-icon-border;
      display: none;
      height: 5px;
      // min-width is needed so that the icon does not get cut off with flexbox
      // when the viewport shrinks
      min-width: 20px;
      width: 20px;
    }

    &:hover {
      box-shadow: 0 2px 4px 0 hsla(200, 20%, 10%, 0.2);
      cursor: pointer;

      .ExUi_drag-column__icon {
        display: block;
      }
    }

    &.dragging {
      box-shadow: 0 2px 4px 0 hsla(200, 20%, 10%, 0.2);
      opacity: 0.2;
    }

    &__label {
      font-weight: $font-weight-regular;
      margin: 0 3px;
    }

    &__column-label-group {
      align-items: center;
      display: flex;
    }

    .icn--clear {
      // min-width is needed so that the clear icn does not get cut off with flexbox
      // when the viewport shrinks
      min-width: 20px;
    }
  }
}
