@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_AndMoreTooltip {
  white-space: nowrap;

  &__text {
    color: $color-text-and-more-tooltip;
    cursor: default;
    display: inline-block;
    font-weight: $font-weight-bold;
    margin-left: 5px;
  }
}
