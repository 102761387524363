@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.asset-cloud-resource-export-dropdown .dropdown-content {
  width: pxToRem(250);
}

.centered-table-message {
  margin: 0 auto;
  position: absolute;
  text-align: center;
  top: pxToRem(200);
  width: 100%;
}
