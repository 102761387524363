@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi {
  &_activity-status {
    display: flex;

    .icn {
      cursor: auto;
      margin-right: 7px;
    }
  }
}
