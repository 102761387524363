@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
@import '~react-toastify/dist/ReactToastify.css';

// stylelint-disable
// override to center the toast container horizontally
.Toastify__toast-container--top-center {
  width: auto;
}

.Toastify {
  &__toast {
    background-color: $color--toast--bg;
    color: $color--toast--title;
    font-family: $font-family-primary-proportional;
    width: 600px;

    &-body {
      padding: 0;
    }

    &-header {
      font-size: 14px;
      line-height: 21px;
    }

    &-description {
      color: $color--toast--text;
      font-size: 12px;
      line-height: 18px;
    }

    &-icon {
      margin-inline-end: 8px;
    }

    &--success,
    &--info,
    &--warn,
    &--error {
      background: $color--toast--bg;
      color: $color--toast--title;
      padding: 16px 16px 16px 8px;
    }

    &--success {
      border-left: 4px $color--toast--success solid;
    }

    &--info {
      border-left: 4px $color--toast--neutral solid;
    }

    &--warn {
      // border-left: 6px rgba(190,38,79,1) solid;
    }

    &--error {
      border-left: 4px $color--toast--error solid;
    }
  }

  /*
    Push the x off the screen and replace with our own times icon
  */
  &__close-button {
    align-self: center;
    background: url(../../assets/images/icons/times.svg);
    background-position: center;
    background-size: 100%;
    height: 20px;
    margin-left: 54px;
    overflow: hidden;
    text-indent: -99999px;
    width: 20px;
  }
}
