@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.sub-nav-item {
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: 0 pxToRem(18);

  > * {
    display: flex;
    align-items: center;
  }

  em {
    color: rgba(255, 255, 255, 0.8);
    font-weight: bold;
    font-style: normal;
    padding: 0 0 0 pxToRem(8);
  }

  &:last-child {
    border: 0;
  }
  &:first-child {
    border-right: 1px solid hsla(212, 33%, 94%, 1);
    padding: 0 0 0 pxToRem(4);
  }
}
