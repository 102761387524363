@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.perimeter-component {
  .cn--prime {
    width: auto;
    flex-grow: 1;
  }
}

.perimeter-sub-view {
  height: 100%;

  &.cn--assets-table {
    .rt-td {
      word-break: break-all;

      .ellipsis-data-cell .ellipsis-tooltip .cn--ellipsis {
        left: 0px;
        letter-spacing: 2px;
      }
    }

    &.flexible-details {
      .card-table .rt-tbody {
        background-color: hsla(0, 0%, 100%, 1);
      }

      .assets-table-tr {
        cursor: pointer;
      }
    }
  }
}

.asset-domain-export-dropdown .dropdown-content {
  width: pxToRem(215);
}

.asset-certificate-export-dropdown .dropdown-content {
  width: pxToRem(255);
}
