@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
@import '../FullColumnConfig';
@import '../../DragColumn/DragColumn';
@import '../mixins';

$horizontal-margin: 10px;

.ExUi {
  &_active-columns {
    @include title;
    @include draggable-container;

    border-right: 1px solid $color-config-column-border;
    display: flex;
    flex: 0 1 23%;
    flex-direction: column;
    padding: $content-margin-top 0 0 $horizontal-margin;

    &__title {
      color: $color--gray-90;
      font-size: $font-size-m;
      margin-left: 10px;

      &-description {
        color: $color--gray-60;
        font-size: $font-size-s;
        margin: 5px 0 16px 0;
      }
    }

    .ExUi_drag-column {
      margin-left: 0;
      margin-right: 0;
      padding-right: 5px;

      &__icon {
        display: block;
        margin-right: 10px;
      }

      .icn--clear {
        // to center the clear icon
        background-position: -458px -30px;
        height: 20px;
        // to make the clickable area larger
        width: 20px;
      }
    }
  }
}
