@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
@import '../mixins';

.ExUi {
  &_drag-container {
    @include scrollbar;
    overflow: scroll;
    width: calc(100% - 3px);
  }
}
