@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
// stylelint-disable plugin/selector-bem-pattern
@import '../../assets/styles/mixins';

$size-nav-side-panel-collapse-width: calc(18 / 14) * 1rem;

.cn--collapsible-panel {
  background: $color-bg-panel;
  box-shadow: inset -1px 0 0 0 $color--border-panel;
  display: flex;
  flex-direction: column;
  width: $size-nav-side-panel-filter;

  .cn--panel-content {
    box-sizing: border-box;
    display: flex;
    overflow-y: auto;
  }

  .header--collapsible-panel {
    color: $color--text-3;
    display: flex;
    flex-shrink: 0;
    font-size: pxToRem(16);

    .header--collapsible-panel--name {
      padding: 0.75rem 0.3rem 0 1rem;
      width: 100%;
    }

    .btn--toggle-collapse {
      @include sprite(18, 36, 0, 90);
      margin-left: auto;
      margin-right: 0.5rem;
    }
  }

  &.mod--toggle-collapsed {
    width: $size-nav-side-panel-collapse-width;

    .header--collapsible-panel--name,
    .cn--panel-content {
      display: none;
    }

    .btn--toggle-collapse {
      @include sprite(18, 36, 30, 90);
      margin-right: 0;
    }
  }
}
