@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_ {
  &LandingPage {
    &__bannerLink {
      color: $color--blue-sf;
      cursor: pointer;
      display: block;
      font-weight: $font-weight-medium;
      margin: 10px 0 0;
      width: fit-content;
    }
  }
}
