@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_IssuesList {
  background: transparent;
  display: flex;
  flex-flow: column;
  height: 100%;

  &__header {
    border-bottom: 1px solid $color--border--panel;
  }

  &__pageTitle {
    font-size: pxToRem(18);
    margin-right: pxToRem(40);
    padding-bottom: 2.5px;
    padding-top: 4.5px;
  }
}
