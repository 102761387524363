@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
// stylelint-disable

// import fonts
// roboto mono
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;300&display=swap');
// open sans
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display');
//
// Lato font is loaded in expander-legacy-ui via the assets folder

// Base styles
html {
  font-size: 87.5%;
  position: relative;
}

body {
  color: $color--txt-secondary;
  font-family: $font-family-primary-proportional;
  position: relative;
  width: 100%;

  input {
    font-family: $font-family-primary-proportional;
  }

  .print-header,
  .print-footer {
    display: none;
  }
}

.issues,
.ExUi_view {
  a {
    color: $color--text-link;
    text-decoration: none;
    font-weight: $font-weight-medium;
  }

  // This code is commented to fix the focus issue caused by PR https://github.q-internal.tech/qadium/expander-ui/pull/970
  // This code change was done using PR https://github.q-internal.tech/qadium/expander-legacy-ui/pull/3596
  // TODO: https://qadium.atlassian.net/browse/DESIGN-2874
  // a,
  // button {
  //   @include focus-indicator;
  // }

  p {
    margin: 0 0 0.5em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color--txt-secondary;
    margin: $component-spacing-xxs 0;
  }

  h1 {
    font-size: $font-size-xxl;
    font-weight: $font-weight-medium;
    line-height: $line-height-xxl;
    margin: 0 0 $component-spacing-xxs;
    padding: 0;
  }

  h2 {
    font-size: $font-size-xl;
    font-weight: $font-weight-medium;
    line-height: $line-height-xl;
    padding: 0;
  }

  h3 {
    font-size: $font-size-l;
    font-weight: $font-weight-regular;
    line-height: $line-height-l;
  }

  h4 {
    font-size: $font-size-m;
    font-weight: $font-weight-regular !important;
    line-height: $line-height-m;
  }
}

.sr-only {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  padding: 0 !important;
  border: 0 !important;
  white-space: nowrap !important;
}

//region Global Print Styles
@media print {
  body {
    color: $color--text-print1;
    margin: 0;
    a,
    .ExUi_view a {
      color: $color--text-print1;
    }

    .icn {
      color-adjust: exact !important;
      // This is used to make sure that the CSS sprites show up in print to PDF views,
      // otherwise most browsers will not "print" those because they are background images.
      print-color-adjust: exact !important; /* Chrome, Safari */
    }
    .ExUi_details-view__main {
      display: block;
    }

    .ExUi_details-view section {
      border-bottom: 1px solid $color--border-print;
      border-radius: 0;
      box-shadow: none;
      color-adjust: exact !important;
      print-color-adjust: exact !important; /* Chrome, Safari */
    }

    .ExUi_details-view section:nth-child(even) {
      break-after: auto;
    }

    .ExUi_ASABar {
      display: none;
    }

    .btn-print-to-pdf {
      display: none;
    }

    .react-flow {
      overflow: visible;
      height: 1000px;
    }

    .expander-version-string {
      display: none;
    }

    .cn--expander {
      expander {
        display: block;
        height: auto;

        > ui-view {
          height: auto;
        }
      }
    }

    .cn--menu-bar {
      display: none;
    }
    .ExUi_details-view__header {
      display: none;
    }
  }
  @page {
    break-after: always;
    padding: 0.25in;
    margin: 0.5in;
    overflow: visible;
    size: 8.25in 10in;
  }
}
//endregion Global Print Styles

// Legacy  styles.
html {
  font-family: $font-family-primary-proportional;
  font-size: calc($size-font-root/$size-font-root-browser) * 100%;
  font-weight: $font-weight-regular;
}

.cn--expander {
  background-color: $color-bg-body;
  display: block;
}

@import 'legacy/icons';
@import 'legacy/buttons';
@import 'legacy/forms';
@import 'legacy/LoadingBackground';
