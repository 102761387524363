$font-family-primary-proportional: 'Lato', Helvetica Neue, sans-serif;
$font-family-primary-mono: 'Roboto Mono', monospace;
$font-family-display-proportional: 'Open Sans', 'Helvetica Neue', sans-serif;

//Weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Font Sizes
// If possible, please use $font-size and it's corresponding $line-height
// in conjunction, the font sizing is defined by both properties
$font-size-xs: 11px;
$line-height-xs: 16px;

$font-size-s: 12px;
$line-height-s: 18px;

$font-size-m: 14px;
$line-height-m: 21px;

$font-size-l: 16px;
$line-height-l: 24px;

$font-size-xl: 20px;
$line-height-xl: 30px;

$font-size-xxl: 24px;
$line-height-xxl: 36px;

$font-size-xxxl: 32px;
$line-height-xxxl: 40px;
