@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
// stylelint-disable
.ExUi {
  &_ip_details_services_panel {
    &__header {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    &__classifications {
      &_tag {
        color: $color--gray-90;
        font-size: 11px;
        padding-right: 14px;
      }
    }
  }

  &_ip_details_service_panel {
    &__header_right {
      align-items: center;
      display: flex;

      // Button style overrides
      & button.ExUi_btn:first-child {
        border-bottom-right-radius: 0%;
        border-top-right-radius: 0%;
        margin-right: 0;
      }

      & button.ExUi_btn:last-child {
        border-bottom-left-radius: 0%;
        border-top-left-radius: 0%;
      }
      // Increase specificity of selector to ensure outline is applied
      & button.ExUi_btn--tertiary,
      & button.ExUi_btn--outline-muted {
        border: 1px solid #e3e7eb;
      }
    }
  }
}

// Panel Table style overrides
.ExUi_panel_table tr.ExUi_ip_details_services_panel__row {
  border-bottom: none;
}
