// Layout spacing Sizes
$layout-component-spacing-x-small: 16px;
$layout-component-spacing-medium: 24px;
$layout-component-spacing-x-large: 32px;
$layout-component-spacing-xxx-large: 40px;
$layout-component-spacing-max-oversize: 48px;

//Component spacing Sizes
$component-spacing-min: 4px;
$component-spacing-xxs: 8px;
$component-spacing-xs: 16px;
$component-spacing-m: 24px;
$component-spacing-l: 32px;
$component-spacing-xl: 40px;
$component-spacing-max: 48px;
