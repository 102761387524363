@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
div.last-updated {
  font-family: $font-family-primary-proportional;
  font-weight: $font-weight-regular;
  color: #475a6c;
  width: max-content;
  padding-left: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  b {
    font-weight: $font-weight-medium;
  }

  span.last-updated-info {
    position: relative;
  }

  span.last-updated-text {
    padding-left: 4px;
  }

  div.loading-indicator {
    display: inline;
    position: relative;

    svg {
      transform: scale(0.5);
    }

    span {
      position: absolute;
      top: -7px;
      left: -7px;
    }
  }
}
