@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_asset-domain-details {
  .data-list {
    .data-list-item-key {
      min-width: 14rem !important;
      text-align: end;
    }

    a {
      padding-left: pxToRem(10);
    }
  }

  .ips {
    table {
      thead {
        font-weight: $font-weight-medium;

        td {
          padding-bottom: pxToRem(10);
        }
      }

      td {
        padding-bottom: pxToRem(5);
        padding-left: pxToRem(60);

        &:first-child {
          padding-left: 0;
        }
      }
    }

    .title {
      span {
        color: $color--text-3;
        font-weight: $font-weight-light;
        margin-left: pxToRem(10);
      }
    }
  }

  .ExUi_resource-table,
  .ExUi_subdomains-table {
    thead {
      font-weight: $font-weight-medium;

      td {
        padding-bottom: pxToRem(10);
      }
    }

    tbody {
      td {
        text-align: right;
      }
    }

    td {
      padding-left: pxToRem(15);

      &:first-child {
        padding-left: 0;
      }
    }
  }
}
