@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
@import '../../../../node_modules/react-grid-layout/css/styles.css';
@import '../../../../node_modules/react-resizable/css/styles.css';

div.dashboards-container {
  height: calc(100% - var(--dashboard-container-offset));

  div.dashboard-content--wrapper {
    background: $color--gray-05;
    overflow: auto;
    max-height: calc(100% - 70px);

    &.no-filter-bar {
      max-height: 100% !important;
    }

    div.dashboard-content {
      padding: 25px 35px;

      div.dashboard-headers {
        margin-bottom: 25px;
        position: relative;
        display: flex;

        &--column-flex {
          @extend div, .dashboard-headers;
          flex-direction: column;

          div.dashboard-headers--inline-flex {
            display: inline-flex;
          }
        }

        h4 {
          margin-top: 0;
          margin-bottom: 0;
          font-size: pxToRem(20);
          color: $color--dashboards--page-header;
          font-weight: 400;
          display: flex;
          align-items: center;
        }

        button {
          margin-left: auto;
          margin-right: 0;
        }

        div.dashboard-buttons {
          margin-left: auto;
          margin-right: 0;

          div.dashboard-file-download-wrapper {
            display: inline-flex;
          }

          button {
            margin-left: 10px;
            margin-right: 0;
          }
        }

        &.extra-padding {
          margin-bottom: 50px;
        }
      }
    }

    .extra-padding-chart {
      margin-top: 50px;
    }

    .stacked-metric-panel-1 {
      margin-top: -30px;
    }

    .stacked-metric-panel-2 {
      margin-top: -60px;
    }
  }
}
