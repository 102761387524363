@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
@import '../../../assets/styles/variables.scss';

.ExUi {
  &_config-column-header {
    border-bottom: 1px solid $color-config-column-border;
    color: $color--gray-90;
    font-weight: $font-weight-regular;
    padding: 20px;

    &__title {
      display: inline-block;
      font-size: 16px;
    }

    .material-icons {
      cursor: pointer;
      float: right;
      font-size: 18px;
      margin-top: 2px;
    }
  }
}
