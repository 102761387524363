@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.global-search-input {
  padding-top: 4px;
  padding-right: 5px;

  .facet-search__input {
    input[type='text'] {
      background-color: white;
    }
    .facet-search__search-btn {
      background-color: white;
      margin-top: 1px;
    }
  }

  .facet-search__menu-panel {
    display: none;
  }

  .facet-search__clear-btn {
    display: none;
  }
}
