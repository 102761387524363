@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.btn {
  &.inline-copy-to-clipboard-button {
    background-color: transparent;
    color: $color--text-link;
    font-size: pxToRem(14);
    font-weight: $font-weight-light;
    padding: 0;

    &:active,
    &:hover {
      background-color: transparent;
      box-shadow: none;
    }
  }
}
