@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
@import '../../assets/styles/variables.scss';
$side-padding: 20px;

.ExUi {
  &_filter-bulk-edit-overlay {
    align-items: center;
    background-color: rgba(51, 51, 51, 0.8);
    display: flex;
    height: 100vh;
    justify-content: center;
    opacity: 1;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 9999;

    &__confirmation-modal,
    &__progress-bar-modal {
      background-color: $color-bg-modal;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 180px;
      width: 460px;
    }

    &__progress-bar-modal {
      width: 500px;
    }

    .text {
      &__confirmation {
        color: $color--text-1;
        font-size: 16px;
        line-height: 24px;
        padding: 18px $side-padding;
      }

      &__header {
        color: $color--text-1;
        font-size: 16px;
        line-height: 18px;
        padding: 21px $side-padding 11px $side-padding;
      }

      &__disclaimer {
        color: $color-text-table;
        font-size: 14px;
        line-height: 18px;
        padding: 17px $side-padding 0 $side-padding;
      }

      &__error {
        color: $color--error;
        font-size: 12px;
        line-height: 18px;
        padding: 0 $side-padding;
        white-space: pre-line; // To allow '/n' to start a new line
      }
    }

    &__footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px;

      .right-most-button {
        margin: 0;
      }
    }
  }
}
