@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
// stylelint-disable
.issue-details {
  &__name {
    font-family: $font-family-primary-proportional;
  }
}

.ExUi {
  &_issue-evidence {
    .cn--evidence {
      .evidence-list {
        &.noTopMargin {
          margin-top: 0;
        }

        &.noBtmMargin {
          margin-bottom: 0;
        }
      }
    }
  }
}
