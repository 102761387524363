@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
div.issues-legend {
  width: 130px;
  color: $color--dashboards--map-overview--legend--text;
  margin: 25px !important;
  background: $color--dashboards--map-overview--legend--bg;
  padding: 5px 0;
  font-family: $font-family-primary-proportional;

  div.priority-section,
  div.total-count-section {
    overflow: hidden;

    h3 {
      font-family: $font-family-primary-proportional;
      color: $color--dashboards--map-overview--legend--text;
      font-size: 12px;
      font-weight: $font-weight-medium;
      letter-spacing: 0;
      line-height: 1px;
      padding-top: 5px;
      padding-left: 15px;
    }

    div.legend-entry {
      padding: 3px 0 3px 15px;
      display: inline-flex;
      width: 100%;
    }
  }

  div.priority-section {
    div.legend-entry:hover {
      cursor: pointer;
      background: $color--dashboards--map-overview--legend--hover-bg;
    }
  }
}

div.legend-icn {
  border-radius: 50%;
  margin: auto 5px;

  &.extra-small {
    height: 6px !important;
    width: 6px !important;
    margin: auto 12px;
  }

  &.small {
    height: 10px !important;
    width: 10px !important;
    margin: auto 10px;
  }

  &.medium {
    height: 14px !important;
    width: 14px !important;
    margin: auto 8px;
  }

  &.large {
    height: 17px !important;
    width: 17px !important;
    margin: auto 7px;
  }

  &.extra-large {
    height: 21px !important;
    width: 21px !important;
  }

  &.default--color {
    background-color: $color--dashboards--map-overview--icon--default-bg;
  }

  &.critical-high--color {
    background-color: $color--dashboards--map-overview--icon--critical-high-bg;
  }

  &.medium--color {
    background-color: $color--dashboards--map-overview--icon--medium-bg;
  }

  &.low--color {
    background-color: $color--dashboards--map-overview--icon--low-bg;
  }

  &.unselected {
    opacity: 0.8 !important;
  }

  &.selected {
    opacity: 1 !important;
  }
}
