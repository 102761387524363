@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.delete-modal {
  .expander-modal .expander-modal-container {
    width: 35vw;
    height: 10vh;
  }

  &.fadingOut {
    .expander-modal-container {
      @include transition(opacity 0.5s ese 1s);
      opacity: 0;
    }
  }
}
