@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.global-search-list {
  margin: 20px;
  .search-list-item {
    padding: 20px;
  }

  .ExUi_DataList {
    &__dataListItemKey {
      min-width: 14rem;
      text-align: end;
      width: 30%;
    }

    &__dataListItemValue {
      width: 65%;
      word-wrap: break-word;
    }
  }

  .ExUi_DataList__dataListItemValue:nth-child(2) {
    font-family: $font-family-primary-mono;
    font-size: pxToRem(13);
  }

  em {
    background-color: yellow;
  }

  .global-search-list-msg-banner {
    text-align: center;
  }

  .loading-indicator__animation {
    display: block;
  }

  .data-toggle-btn {
    position: absolute;
    right: 5px;
  }
}
