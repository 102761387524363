@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
// necessary hack to overwrite CSS from issues-ui
// issues-ui should be using react-select config options
// https://react-select.com/styles
.ExUi_details-header__tool.breadcrumb-select-container {
  height: 100%;
  display: inline-block;

  .toolbar-select__dropdown-indicator {
    padding: 0;
  }

  div.toolbar-select {
    margin: 0;
    min-width: unset;
  }

  div.toolbar-select__single-value {
    color: #475a6c;
    font-size: 20px;
  }

  input {
    &::placeholder {
      color: #2a303c;
      opacity: 0.6;
      font-family: $font-family-primary-proportional;
    }
  }

  div.toolbar-select__menu {
    div.toolbar-select__menu-list {
      .toolbar-select__option.toolbar-select__option--is-selected {
        background-color: #f3f8ff;
      }

      .toolbar-select__option.toolbar-select__option--is-focused {
        background-color: #dce9f7;
      }

      div.toolbar-select__option {
        color: #323843;

        span.opt {
          span.icn {
            margin-right: 8px;
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }

  div.toolbar-select__value-container {
    padding: unset;
  }
}
