@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.card {
  border-radius: 5px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  height: 160px;
  justify-content: space-between;
  padding: 14px 12px;
  width: 200px;

  &__title {
    font-size: 18px;
    font-weight: $font-weight-regular;
    margin-bottom: 3px;
  }

  &__meta {
    font-size: 14px;
  }

  &__footer {
  }
}
