@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_AssetsInventoryList {
  display: flex;
  height: 100%;

  &__deviceTypeIcon {
    display: inline-block;
    height: 18px;
    margin-right: 4px;
    margin-top: -4px;
    vertical-align: middle;
  }

  &--columns {
    .ExUi_available-columns__classification-column-group-header {
      display: none;
    }

    .ExUi_SearchBar {
      width: 293px;
    }

    .ExUi_SearchBar__filterIcon {
      left: 268px;
      position: absolute;
      top: 6px;
    }
  }
}
