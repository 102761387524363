@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_AssociatedAssetAnnotation {
  margin: 0 0 30px;

  &:first-child {
    margin-top: 30px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__header {
    margin-bottom: 6px;
  }

  &__title {
    color: $color--gray-60;
    font-weight: $font-weight-regular;
    vertical-align: top;
  }

  &__icon {
    cursor: auto;
    margin-right: 5px;
    vertical-align: super;
  }
}
