@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
div.dashboard-compliance-unevaluated-families {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  margin-top: 15px;

  h3 {
    margin: 5px 0 15px;
    color: #475a6c;
    font-family: $font-family-primary-proportional;
    font-weight: $font-weight-medium;
    font-size: 18px;
  }

  p {
    margin: 0;
    color: #323843;
    font-family: $font-family-primary-proportional;
    font-weight: $font-weight-regular;
    font-size: 14px;
  }
}

a.dashboard-compliance-link {
  color: #1157b0;

  &:visited {
    color: #1157b0;
  }

  &:active {
    color: #1157b0;
  }

  &:hover {
    color: #1157b0;
  }
}
