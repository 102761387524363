@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.details-header {
  &-back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: pxToRem(40);
  }

  .chevron {
    display: inline-block;
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    width: pxToRem(10);
    height: pxToRem(10);

    &--left {
      transform: rotate(-225deg);
    }
  }
}
