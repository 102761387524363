@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.cn--tags {
  .cn--add-form {
    .cn--input {
      input:placeholder-shown + * {
        background: red;
      }
    }
  }
}

.tags-input-info {
  color: $color--text-3;
  font-size: pxToRem(12);
  margin-top: pxToRem(10);
}
