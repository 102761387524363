@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.password-input {
  &-show-toggle {
    color: $color--blue-primary;
    cursor: pointer;
    font-size: 10px;
    margin-left: 10px;
    position: relative;
    top: -1px;
  }
}
