@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
@import '../FullColumnConfig';
@import '../ColumnFacetSearch/ColumnFacetSearch';
@import '../../ConfigColumnButton';
@import '../mixins';
@import '../../mixins';

$search-bar-height: 30px + $vertical-margin;

.ExUi {
  &_available-columns {
    @include title;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: $content-margin-top 0 0 $content-margin-left;

    &__title {
      color: $color--gray-90;
      font-size: $font-size-m;

      &-description {
        color: $color--gray-60;
        margin: $title-description-margin-top 0 0 0;
      }
    }

    h4 {
      &:first-of-type {
        margin-top: 0;
      }
    }

    &__column-groups {
      @include scrollbar;
      overflow: scroll;
      width: calc(100% - 3px);
    }

    &__classification-column-group {
      margin: 10px 0 25px;
    }

    &__classification-column-group-header {
      color: $color-full-column-config-classification-column-group-header-text;
      display: inline;
      font-size: 13px;
      font-weight: $font-weight-medium;
      line-height: 15px;
    }

    &__column-options {
      display: grid;
      grid-column-gap: 30px;
      grid-template-columns: 1fr 1fr 1fr;
      list-style: none;
      padding: 0;
      width: 80%;

      &.single-col {
        grid-template-columns: 1fr;
      }

      &.global {
        margin: 13px 0 34px;
      }
    }

    &__column-option {
      align-items: center;
      color: $color-full-column-config-column-option-text;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-weight: $font-weight-regular;
      line-height: 15px;
      margin: 10px 0;

      &.disabled {
        cursor: not-allowed;
      }

      input[type='checkbox'] {
        margin: 0;
      }
    }

    &__column-name {
      margin-left: 10px;
    }

    &__no-columns {
      color: $color-full-column-config-no-columns-text;
      margin-bottom: 34px;
    }
  }
}
