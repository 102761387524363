@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.expander-tabs {
  background: transparent;
  display: flex;
  flex-flow: column;
  height: 100%;
  white-space: nowrap;
  width: 100%;
}

.expander-tab-list {
  background: $color-nav-menu-bar-bg-2;
  box-shadow: inset 0 -1px 0 $color--border--panel;
  display: inline-flex;
  flex: 0 1 auto;
  flex-flow: row;
  margin: 0;
  padding-left: 12px;
}

.expander-tab {
  background: transparent;
  box-sizing: border-box;
  color: $color-sub-nav-text-link;
  display: flex;
  flex: 0 1 auto;
  height: 40px;
  margin-right: 8px;
  padding: 12px 16px 10px;

  // This code is commented to fix the focus issue caused by PR https://github.q-internal.tech/qadium/expander-ui/pull/970
  // This code change was done using PR https://github.q-internal.tech/qadium/expander-legacy-ui/pull/3596
  // TODO: https://qadium.atlassian.net/browse/DESIGN-2874
  //z-index: 1;

  &:hover {
    border-bottom: 3px solid $color-sub-nav-border-bottom-hover;
    color: $color-sub-nav-text-link;
    cursor: pointer;
  }

  &.mod--active {
    border-bottom: 3px solid $color-sub-nav-border-bottom-active;
    color: $color-sub-nav-text-link;
    font-weight: $font-weight-bold;
    z-index: 1;
  }
}

.expander-tab-panel {
  background: $color-bg-body;
  display: block;
  flex: 1 1 auto;
  position: relative;
  width: 100%;

  .expander-tab-panel-content {
    height: 100%;
    position: absolute;
    width: 100%;
  }
}
