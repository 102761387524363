@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_ {
  &DownloadTextButton {
    background: transparent;
    border: 0;
    color: $color--text-link;
    cursor: pointer;
    font-size: pxToRem(12);
    padding: 0;
  }
}
