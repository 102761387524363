@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_LinkToView {
  align-items: flex-end;
  background: none;
  border: 0;
  color: $color--text-link;
  display: flex;
  padding: 0;

  &:hover {
    background: none;
    color: $color--text-link-hover;
    cursor: pointer;

    &:disabled {
      color: $color-text-button-disabled;
      cursor: not-allowed;
    }
  }

  &:disabled {
    color: $color-text-button-disabled;
    cursor: not-allowed;
  }
}
