@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.tippy-box[data-animation='fade'][data-state='hidden'] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  font-size: 14px;
  line-height: 1.4;
  outline: 0;
  position: relative;
  transition-property: transform, visibility, opacity;

  &[data-placement^='top'] > .tippy-arrow {
    bottom: 0;
  }

  &[data-placement^='top'] > .tippy-arrow::before {
    border-top-color: initial;
    border-width: 8px 8px 0;
    bottom: -6px;
    left: -3px;
    transform-origin: center top;
  }

  &[data-placement^='bottom'] > .tippy-arrow {
    top: 0;
  }

  &[data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: initial;
    border-width: 0 8px 8px;
    left: -3px;
    top: -6px;
    transform-origin: center bottom;
  }

  &[data-placement^='left'] > .tippy-arrow {
    right: 0;
  }

  &[data-placement^='left'] > .tippy-arrow::before {
    border-left-color: initial;
    border-width: 8px 0 8px 8px;
    right: -6px;
    transform-origin: center left;
  }

  &[data-placement^='right'] > .tippy-arrow {
    left: 0;
  }

  &[data-placement^='right'] > .tippy-arrow::before {
    border-right-color: initial;
    border-width: 8px 8px 8px 0;
    left: -6px;
    transform-origin: center right;
  }

  &.show-arrow {
    .tippy-arrow {
      display: block;
    }
  }

  .tippy-arrow {
    display: none;
    height: 12px;
    width: 12px;
  }

  .tippy-arrow::before {
    border-color: transparent;
    border-style: solid;
    content: '';
    position: absolute;
  }
}

.tippy-box[data-inertia][data-state='visible'] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}

.tippy-tooltip[data-out-of-boundaries] {
  opacity: 0;
}
