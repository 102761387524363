@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_ {
  &SessionExpirationModal {
    background-color: rgba(50, 56, 67, 0.15);

    &__action {
      background-color: $color--blue-primary;
      font-size: 14px;
      font-weight: $font-weight-medium;
      letter-spacing: 0;
      line-height: 21px;
      margin-top: 25px;

      &:hover {
        background-color: $color--blue-primary-hover;
      }
    }

    &__content {
      background-color: $color--white;
      box-shadow: $shadow-03;
      box-sizing: border-box;
      padding: 25px;
      position: relative;
      text-align: center;
      width: 400px;
    }

    &__close {
      background: transparent;
      border: 0;
      cursor: pointer;
      padding: 0;
      position: absolute;
      right: 25px;
      top: 25px;
    }

    &__description {
      color: $color--gray-90;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0;
      line-height: 21px;
      margin-top: 15px;
    }

    &__hourglass {
      margin-top: 25px;
    }

    &__timer {
      color: $color--gray-90;
      font-size: 24px;
      font-weight: $font-weight-medium;
      margin-top: 25px;
    }

    &__title {
      color: $color--steel-blue-70;
      font-size: 18px;
      font-weight: $font-weight-medium;
      margin-top: 15px;
    }
  }
}
