@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi {
  &_switch {
    display: inline-block;
    font-size: 12px;
    height: 24px;
    line-height: 18px;
    position: relative;
    width: 72px;

    &__slider {
      align-items: stretch;
      border: 1px solid $color--slider--border;
      border-radius: 4px;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      flex-flow: row nowrap;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: 0.4s ease-in-out;
      width: 100%;

      &:hover {
        & .ExUi_switch__label--on {
          border-color: $color--slider--on--border--hover;
        }

        & .ExUi_switch__label--off {
          border-color: $color--slider--off--border--hover;
        }
      }

      &::after {
        background-color: $color--slider--switch--bg;
        content: '';
        display: inline-block;
        height: 100%;
        position: absolute;
        top: 0;
        transition: all 0.4s ease-in-out;
      }

      &.is-disabled {
        border: 0;
        color: $color--slider--text;
        cursor: not-allowed;

        & .ExUi_switch__label--on,
        & .ExUi_switch__label--off {
          background-color: transparent;
          border: 0;
        }

        & .ExUi_switch__label--on {
          &.is-checked {
            background-color: $color--slider--on--bg;
          }
        }

        & .ExUi_switch__label--off {
          &.is-checked {
            background-color: $color--slider--off--bg;
          }
        }

        .ExUi_switch__label {
          border-radius: 4px;
          box-shadow: none;
        }

        &::after {
          background-color: transparent;
          content: '';
          display: inline-block;
          height: 100%;
          position: absolute;
          top: 0;
          transition: all 0.4s ease-in-out;
        }
      }
    }

    &__label {
      box-shadow: inset 0 1px 3px 0 hsla(0, 0%, 0%, 0.15);
      box-sizing: border-box;
      color: $color--slider--text;
      display: inline-block;
      font-family: $font-family-primary-proportional;
      font-weight: $font-weight-bold;
      letter-spacing: 1px;
      padding: 3px 6px;
      text-align: center;
      user-select: none;

      .ExUi_switch__label__text {
        display: inline-block;
      }

      &--on {
        background-color: $color--slider--on--bg;
        border-radius: 3px 0 0 3px;
        border-right: 1px solid $color--slider--border;
        min-width: 32px;
        transition: 0.4s ease-in-out;
      }

      &--off {
        background-color: $color--slider--off--bg;
        border-radius: 0 3px 3px 0;
        min-width: 38px;
      }
    }

    &__input {
      opacity: 0;

      &:checked {
        + .ExUi_switch__slider::after {
          border-radius: 0 2px 2px 0;
          margin-left: 32px;
          width: 38px;
        }

        + .ExUi_switch__slider .ExUi_switch__label--off {
          .ExUi_switch__label__text {
            display: none;
          }
        }
      }

      &:not(:checked) {
        + .ExUi_switch__slider::after {
          border-radius: 2px 0 0 2px;
          margin-left: 0;
          width: 31px;
        }

        + .ExUi_switch__slider .ExUi_switch__label--on {
          .ExUi_switch__label__text {
            display: none;
          }
        }
      }
    }
  }
}
