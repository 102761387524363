@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.cloud-resource-details {
  display: block;

  .data-list {
    .data-list-item-key {
      min-width: 12rem;
      text-align: end;
    }
    a {
      padding-left: pxToRem(10);
    }
  }

  .key-value__container {
    background: $color--gray-10;
    font-size: $font-size-m;
    font-weight: $font-weight-regular;
    letter-spacing: 1px;
    line-height: $line-height-m;
    max-height: 350px;
    overflow-y: auto;
    padding: $component-spacing-xs;
  }
}
