@mixin drop-shadow($value: $color-shadow-modal) {
  box-shadow: 0 0 20px 1px $value;
}

@mixin box-sizing() {
  box-sizing: border-box;
}

@mixin debug($color: red) {
  box-shadow: inset 0 0 1px transparentize($color, 0.3);
}

@mixin arrow($color, $margin) {
  border-color: $color transparent transparent transparent;
  border-style: solid;
  border-width: 10px 6.5px 0;
  height: 0;
  margin: $margin;
  width: 0;
}

// This code is commented to fix the focus issue caused by PR https://github.q-internal.tech/qadium/expander-ui/pull/970
// This code change was done using PR https://github.q-internal.tech/qadium/expander-legacy-ui/pull/3596
// TODO: https://qadium.atlassian.net/browse/DESIGN-2874
// @mixin focus-indicator {
//   &:focus,
//   &:focus-visible {
//     box-shadow: 0 0 0 2px $color--white, 0 0 0 4px $color--blue-sf;
//     outline: 2px solid transparent;
//     outline-offset: -2px;
//   }
// }

@mixin arrow-size($color, $margin, $size) {
  border-color: $color transparent transparent transparent;
  border-style: solid;
  border-width: $size $size * 0.6 0 $size * 0.6;
  height: 0;
  margin: $margin;
  width: 0;
}

@mixin no-shadow() {
  box-shadow: none;
}

@mixin gradient($color) {
  background-image: linear-gradient($color);
  background-image: linear-gradient($color);
  background-image: linear-gradient($color);
  background-image: linear-gradient($color);
}

@mixin transform($value) {
  transform: $value;
}

@mixin truncate($lines) {
  /* stylelint-disable-next-line */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  /* stylelint-disable-next-line */
  -webkit-box-orient: vertical;
  /* stylelint-disable-next-line */
  -webkit-line-clamp: $lines; /* number of lines to show */
  white-space: pre-wrap;
}

@mixin nondrag {
  -webkit-user-drag: none;
}

@mixin border-radius($value: 50%) {
  border-radius: $value;
}

@mixin border-sides($t, $r, $b, $l, $color, $solid) {
  border-bottom: $b $color $solid;
  border-left: $l $color $solid;
  border-right: $r $color $solid;
  border-top: $t $color $solid;
}

@mixin scroll-tooltip {
  /* stylelint-disable-next-line  plugin/selector-bem-pattern */
  &::-webkit-scrollbar {
    height: 6px;
    width: 3px;
  }

  /* stylelint-disable-next-line  plugin/selector-bem-pattern */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* stylelint-disable-next-line  plugin/selector-bem-pattern */
  &::-webkit-scrollbar-thumb:vertical {
    background: $color-scroll-thumb;
    border-radius: 1.5px;
  }

  /* stylelint-disable-next-line  plugin/selector-bem-pattern */
  &::-webkit-scrollbar-thumb:vertical:hover {
    background: rgba(255, 255, 255, 0.8);
  }

  /* stylelint-disable-next-line  plugin/selector-bem-pattern */
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

$scroll-bar-width: 8px;

@mixin scroll {
  /* stylelint-disable-next-line  plugin/selector-bem-pattern */
  &::-webkit-scrollbar {
    height: $scroll-bar-width;
    width: $scroll-bar-width;
  }

  /* stylelint-disable-next-line  plugin/selector-bem-pattern */
  &::-webkit-scrollbar-track {
    background: $color-scroll-track;
  }

  /* stylelint-disable-next-line  plugin/selector-bem-pattern */
  &::-webkit-scrollbar-thumb:vertical {
    background: $color-scroll-thumb;
    border-radius: calc($scroll-bar-width/2);
  }

  /* stylelint-disable-next-line  plugin/selector-bem-pattern */
  &::-webkit-scrollbar-thumb:horizontal {
    background: $color-scroll-thumb;
    border-radius: calc($scroll-bar-width / 2);
  }

  /* stylelint-disable-next-line  plugin/selector-bem-pattern */
  &::-webkit-scrollbar-thumb:vertical:hover {
    background: $color-scroll-thumb-hover;
  }

  /* stylelint-disable-next-line  plugin/selector-bem-pattern */
  &::-webkit-scrollbar-thumb:horizontal:hover {
    background: $color-scroll-thumb-hover;
  }
}

@mixin break-word {
  hyphens: auto;
  overflow-wrap: break-word;
  word-break: break-all;
  word-wrap: break-word;
}

@mixin flex($val: 0, $dir: row, $wrap: wrap) {
  display: flex;
  flex-direction: $dir;
  flex-grow: $val;
  flex-shrink: 1;
  flex-wrap: $wrap;
}

/* stylelint-disable-next-line */
@function pxToRem($value) {
  @return calc($value / $size-font-root) * 1rem;
}

@mixin transition($value...) {
  @if length($value) == 0 {
    $value: all ease 0.2s;
  }
  transition: $value;
}

@mixin animation($value) {
  animation: $value;
}

@mixin blur($value) {
  filter: blur($value);
}

@mixin user-select($value: none) {
  user-select: $value;
}

@mixin animation-bg-darken-pulse($button-color, $animation-name) {
  animation: #{$animation-name} 0.85s ease alternate infinite;
  @keyframes #{$animation-name} {
    from {
      background-color: darken($button-color, 20%);
    }

    to {
      background-color: darken($button-color, 10%);
    }
  }
}

@mixin animation-bg-darken-pulse-light($button-color, $animation-name) {
  animation: #{$animation-name} 0.85s ease alternate infinite;
  @keyframes #{$animation-name} {
    from {
      background-color: darken($button-color, 8%);
    }

    to {
      background-color: darken($button-color, 3%);
    }
  }
}
@mixin sprite($width, $height, $x, $y, $scale-factor: 1) {
  background-position: ($x * $scale-factor) * -1px ($y * $scale-factor) * -1px;
  background-size: $scale-factor * $sprite-file-width;
  height: $height * $scale-factor * 1px;
  width: $width * $scale-factor * 1px;
}

@mixin sprite-alt($x, $y, $scale-factor: 1) {
  background-position: ($x * $scale-factor) * -1px ($y * $scale-factor) * -1px;
}

@mixin animated-icon() {
  backface-visibility: hidden;
  background-image: url('../images/sprites/qadium-sprite-animated.svg');
  perspective: 1000px;
  transform: translateZ(0);
}

@mixin sprite-background() {
  background-image: url('../images/sprites/qadium-sprite.svg');
}

@mixin font-metric-callout-medium {
  font-family: $font-family-opensans;
  font-weight: $font-weight-medium;
}

@mixin font-default-light {
  font-family: $font-family-roboto;
  font-weight: $font-weight-light;
}

@mixin font-default-regular {
  font-family: $font-family-roboto;
  font-weight: $font-weight-regular;
}

@mixin font-default-medium {
  font-family: $font-family-roboto;
  font-weight: $font-weight-medium;
}

@mixin font-default-bold {
  font-family: $font-family-roboto;
  font-weight: $font-weight-bold;
}
