@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.header-checkbox,
.row-checkbox {
  background-color: rgba(255, 255, 255, 1);
  margin: 1px 0 1px 1px;
}

.checkbox-dropdown {
  border: 0;
  padding: 0 6px 0 0 !important;

  &-label {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  &-menu-item {
    line-height: 18px !important;
    padding: 9px 33px 9px 16px !important;
  }

  &-menu-list {
    border-radius: 0;
    margin-block-end: 0;
    margin-block-start: 0;
  }
}

.checkbox-dropdown-clickable-container {
  margin: -6px -7px;
  position: absolute;
  z-index: 2;

  .header-checkbox-clickable-area,
  .checkbox-dropdown-clickable-area {
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-block;
    height: 30px;
    width: 25px;

    &.disabled {
      cursor: not-allowed;
    }
  }

  .checkbox-tooltip {
    display: inline-block;
  }
}
