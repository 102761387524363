.expander-modal {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;

  .expander-modal-overlay {
    /* A dark translucent div that covers the whole screen */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.8;
  }

  .expander-modal-container {
    position: relative;
    z-index: 10000;
    width: 95vw;
    @include drop-shadow($color-shadow-modal);
    @include nondrag;
    background: $color-bg-modal;

    .expander-modal-header {
      z-index: 10004;
      position: relative;
      //intentionally done in px, this doesn't need to scale in response to typography
      padding: 10px 10px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      font-weight: $font-weight-regular;
      color: $color-text-modal-header;
      background: $color-bg-modal-header;

      .expander-modal-header-title {
        height: 100%;
        font-size: 1.5em;
      }

      .expander-modal-header-buttons {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .expander-modal-header-button {
          color: $color-icon-modal-header;
          @include transition();
          &:hover {
            color: darken($color-icon-modal-header, 10%);
          }

          // CLOSE BUTTON
          &.expander-modal-close {
            .close-button-wrapper {
              display: inline;

              .close-button-container {
                line-height: 0;
                cursor: pointer;
                padding-left: 0;
                button {
                  background-color: transparent;
                  border: 0;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
    .expander-modal-prime {
      position: relative;
      z-index: 10001;
    }
    .expander-modal-header-panels {
      z-index: 10002;
      position: absolute;
      top: 0;
      right: 0;
    }
    .expander-modal-content {
      position: relative;
    }
  }
}
