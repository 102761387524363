// stylelint-disable

@import '../variables';
@import '../mixins';

// Legacy Icons
.icn {
  @include sprite-background();
  background-repeat: no-repeat;
  box-sizing: border-box;
  color: transparent;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
}

button.icn {
  border: 0;
}

.icn--expanse-logo-rebrand {
  @include sprite(106, 30, 0, 295);
}

.icn--hoverable {
  &:hover {
    filter: brightness(135%);
  }
}

.icn--download-20 {
  @include sprite(20, 20, 0, 30);
}

.mod--is-exporting {
  .icn--download-20 {
    @include sprite(20, 20, 0, 30);
    @include animated-icon;
  }
}

.icn--ok-circle {
  @include sprite(24, 24, 30, 60);

  &.mod--verification-status--vns {
    @include sprite-alt(60, 60);
  }

  &.mod--verification-status--vs {
    @include sprite-alt(90, 60);
  }

  &.mod--verification-status--utv {
    @include sprite-alt(120, 60);
  }
}

.icn--logout {
  @include sprite(30, 30, 100, 210);
}

.icn--settings-lrg {
  @include sprite(30, 30, 60, 210);
}

.icn--settings-btn {
  @include sprite(20, 20, 150, 270);
}

.icn--help {
  @include sprite(30, 30, 140, 210);
}

.icn--help-blue {
  @include sprite(30, 30, 180, 270, calc(18 / 30));
}

.icn--clear {
  @include sprite(16, 16, 460, 30);
}

.mod--toggle-state--category-menu {
  .cn--nav--category-menu {
    .btn--panel-toggle {
      @include sprite(18, 36, 30, 90);
    }
  }
}

.mod--toggle-state--filters {
  .cn--nav--filter {
    .btn--panel-toggle {
      @include sprite(18, 36, 30, 90);
    }
  }
}

.icn--selected-mark-white {
  @include sprite(16, 16, 220, 210);
}

.icn--disclosure-tri {
  @include sprite(16, 16, 520, 30);
}

.mod--asset-category-filter--is-open {
  & > .cn--nav-item > .cn--toggle-ctrl > .icn--disclosure-tri {
    @include sprite(16, 16, 550, 30);
  }
}

.icn--search {
  @include sprite(16, 16, 730, 30);

  &.mod--icn--search-blue {
    @include sprite(16, 16, 330, 270);
  }
}

.icn--filter {
  @include sprite(20, 20, 760, 30);
}

.icn--remove {
  @include sprite(20, 20, 610, 30);

  &:hover {
    @include sprite-alt(640, 30);
  }
}

.icn--sort {
  @include sprite(24, 24, 570, 60);

  &.mod--dir--asc {
    @include sprite-alt(570, 60);
  }

  &.mod--dir--dsc {
    @include sprite-alt(600, 60);
  }
}

.icn--sort-col-header {
  @include sprite(9, 10, 690, 60);

  &.mod--dir--asc {
    @include sprite-alt(690, 60);
  }

  &.mod--dir--dsc {
    @include sprite-alt(720, 60);
  }

  &:hover {
    &.mod--dir--asc {
      @include sprite-alt(750, 60);
    }

    &.mod--dir--dsc {
      @include sprite-alt(780, 60);
    }
  }
}

.icn--knowledgebase {
  @include sprite(30, 30, 180, 210);
}

.accordion-arrow {
  color: transparent;
  // used all over
  display: inline-block;
  // used all over
  margin: 0 10px;
  margin: 0 10px;
  position: relative;
  position: relative;
  user-select: none;

  &::after {
    border-left: 4.04px solid transparent;
    border-right: 4.04px solid transparent;
    border-top: 6px solid $color--text-3;
    content: '';
    cursor: pointer;
    height: 0;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    width: 0;
  }

  &.-open::after {
    transform: translate(-50%, -50%) rotate(0);
  }
}

.icn--flow--dir--ltr {
  @include sprite(28, 28, 350, 90);
  cursor: default;
}

.icn--flow--dir--rtl {
  @include sprite(28, 28, 390, 90);
  cursor: default;
}

.icn--flow--dir--unknown {
  @include sprite(28, 28, 430, 90);
  cursor: default;
}

.icn--parent--no-child {
  @include sprite(16, 16, 460, 90);
}

.icn--parent--with-child {
  @include sprite(16, 16, 490, 90);
}

.icn--child {
  @include sprite(16, 16, 520, 90);
}

.icn--rstatus-none {
  @include sprite(20, 16, 580, 90);
}

.icn--rstatus-investigated {
  @include sprite(20, 16, 730, 90);
}

.icn--rstatus-investigating {
  @include sprite(20, 16, 700, 90);
}

.icn--rstatus-in-progress {
  @include sprite(20, 16, 640, 90);
}

.icn--rstatus-check {
  @include sprite(20, 16, 670, 90);
}

.icn--rstatus-resolved {
  @include sprite(20, 16, 610, 90);
}

.icn--severity--critical {
  @include sprite(20, 20, 90, 180);
}

.icn--severity--warning {
  @include sprite(20, 20, 120, 180);
}

.icn--severity--routine {
  @include sprite(20, 20, 150, 180);
}

.icn--check-small {
  @include sprite(16, 16, 175, 175);
  margin: 0 4px 0 0;
}
.icn--check {
  @include sprite(16, 16, 210, 180);
}

.icn--drop-arrow--down {
  @include sprite(7, 7, 240, 180);

  &.mod--light {
    @include sprite(7, 7, 300, 180);
  }
}

.icn--drop-arrow--right {
  @include sprite(7, 7, 270, 180);

  &.mod--light {
    @include sprite(7, 7, 330, 180);
  }
}

.mod--is-disabled {
  .icn--drop-arrow--down {
    @include sprite(7, 7, 370, 180);
  }

  .icn--drop-arrow--right {
    @include sprite(7, 7, 400, 180);
  }
}

.icn--rows-compact {
  @include sprite(20, 20, 0, 210);
}

.icn--rows-expanded {
  @include sprite(20, 20, 30, 210);
}

.icn--ps--new {
  @include sprite(20, 20, 0, 240);
}

.icn--ps--investigating {
  @include sprite(20, 20, 30, 240);
}

.icn--ps--in-progress {
  @include sprite(20, 20, 60, 240);
}

.icn--ps--verify {
  @include sprite(20, 20, 90, 240);
}

.icn--ps--acceptable-risk {
  @include sprite(20, 20, 120, 240);
}

.icn--ps--resolved {
  @include sprite(20, 20, 150, 240);
}

.icn--ps--no-risk {
  @include sprite(20, 20, 360, 270);
}

.icn--select-dropdown-dark {
  @include sprite(10, 10, 700, 30);
}

.icn--select-dropdown {
  @include sprite(10, 10, 540, 240);
}

.icn--priority--critical {
  @include sprite(20, 20, 180, 240);
}

.icn--priority--high {
  @include sprite(20, 20, 210, 240);
}

.icn--priority--medium {
  @include sprite(20, 20, 240, 240);
}

.icn--priority--low {
  @include sprite(20, 20, 270, 240);
}

.icn--info {
  @include sprite(20, 20, 330, 240);
}

.icn--export {
  @include sprite(20, 20, 340, 210);
}

.icn--healthy {
  @include sprite(20, 20, 380, 210);
}

.icn--back {
  @include sprite(20, 20, 390, 240);
}

.icn--tag {
  @include sprite(20, 20, 420, 240);
}

.icn--business-unit {
  @include sprite(20, 20, 450, 240);
}

.icn--assigned-to {
  @include sprite(20, 20, 480, 240);
}

.icn--update-comment {
  @include sprite(20, 20, 510, 240);
}

.icn--pagination--prev {
  @include sprite(24, 24, 570, 240);
}

.icn--pagination--next {
  @include sprite(24, 24, 600, 240);
}

.icn--select-dropdown-double {
  @include sprite(6, 10, 630, 240);
}

.icn--back-nav {
  @include sprite(20, 20, 660, 240);
}

.icn--refresh {
  @include sprite(20, 20, 0, 270);
}

.icn--active {
  @include sprite(20, 16, 30, 272);
}

.icn--inactive {
  @include sprite(20, 16, 60, 272);
}

.icn--vert-disclosure-tri-open {
  @include sprite(20, 20, 88, 268);
}

.icn--vert-disclosure-tri-closed {
  @include sprite(20, 20, 118, 268);
}

.icn--checkbox-checkmark {
  @include sprite(9, 7, 210, 270);
}

.icn--three-ellipsis {
  @include sprite(30, 20, 270, 270);
}

.icn--right-arrow-dashboards {
  @include sprite(20, 20, 300, 270);
}

.icn--dashboard-actions {
  @include sprite(20, 20, 390, 270);
}

.icn--dashboard-info {
  @include sprite(20, 20, 476, 266);
}

.icn--dashboard-compliance-arrow {
  @include sprite(20, 20, 506, 270);
}

.icn--dashboard-external-link {
  @include sprite(20, 20, 536, 264);
}

.icn--dashboard-chart-error {
  @include sprite(20, 20, 570, 268);
}

.icn--dashboard-actions-white {
  @include sprite(20, 20, 600, 270);
}

.icn--right-arrow-dashboards-light-blue {
  @include sprite(20, 20, 620, 270);
}

.icn--tip {
  @include sprite(20, 20, 660, 265);
}

.icn--close {
  @include sprite(20, 20, 690, 265);
}

.icn--attention {
  @include sprite(20, 20, 690, 240);
}
