@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
div.issue-marker-tooltip {
  width: auto;
  height: auto;
  color: $color--dashboards--map-overview--tooltip--text;
  max-width: 300px;
  min-width: 125px;
  font-family: $font-family-primary-proportional;
  background: rgba(2, 6, 8, 0.5);

  div.location {
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 5px 5px;
    font-size: 12px;
    font-weight: $font-weight-regular;
  }

  table {
    width: 100%;

    tbody {
      tr {
        line-height: 12px;

        .extra-small {
          margin: auto 8px auto 0;
        }

        td.priority {
          display: inline-flex;
          position: relative;
          float: left;
          font-size: 11px;
        }

        td.count {
          position: relative;
          float: right;
          font-size: 12px;
          font-weight: $font-weight-regular;
        }
      }
    }
  }
}

.leaflet-tooltip {
  background-color: $color--dashboards--map-overview--tooltip-bg !important;
  border: 1px solid $color--dashboards--map-overview--tooltip-bg !important;
  opacity: 0.7;
}

.leaflet-tooltip-left:before {
  border-left-color: $color--dashboards--map-overview--tooltip-bg !important;
  opacity: 0.7;
}

.leaflet-tooltip-right:before {
  border-right-color: $color--dashboards--map-overview--tooltip-bg !important;
  opacity: 0.7;
}
