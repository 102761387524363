@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi {
  &_details-view {
    background-color: $color--gray-05;
    min-height: 100%;

    &__header {
      background: #fff;
      padding: 15px 10px;
      position: sticky;
      top: 0;
      z-index: 200;
    }

    &__sidebar {
      flex: 1 0;
      order: 2;
      padding: $size--padding--module $size--padding--module
        $size--padding--module 0;
    }

    &__sidebar-left{
      flex: 1 0;
      order: 0;
      padding: $size--padding--module 0 $size--padding--module
        $size--padding--module;
    }

    &__sidebar-right {
      flex: 2 0;
      order: 2;
      padding: $size--padding--module 0 $size--padding--module
      $size--padding--module;
    }

    &__sidebar-right {
      padding: $size--padding--module $size--padding--module
        $size--padding--module 0;
    }

    &__main {
      align-items: stretch;
      display: grid;
      grid-template-columns: 3fr 562px;
      flex-flow: row wrap;
      justify-content: space-between;
      position: relative;
      z-index: 100;

      &__content {
        flex: 3  0;
        order: 1;
        padding: $size--padding--module;
      }
    }

    &__main,
    &__sidebar {
      h1,
      h2,
      h3,
      h4 {
        font-weight: $font-weight-semibold;
      }

      section,
      summary {
        > h1,
        > h2,
        > h3,
        > h4 {
          &:first-child {
            margin-bottom: 24px;
            margin-top: 0;
          }
        }
      }

      h4 {
        color: $color--text-6;
      }
    }

    &__loading {
      background: rgba(255, 255, 255, 0.8);
      display: flex;
      justify-content: center;
      min-height: 600px;

      .loading-indicator {
        align-self: center;
        display: flex;

        &__msg {
          align-self: center;
          color: $color--text-2;
          font-size: 24px;
          font-weight: $font-weight-medium;
        }

        &__animation {
          margin-top: 7px;
        }
      }

      &--updating {
        background: rgba(255, 255, 255, 0.95);
        height: 100%;
        justify-content: flex-start;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1000;

        .loading-indicator {
          &__msg {
            font-weight: $font-weight-light;
          }
        }
      }
    }

    section {
      background-color: hsla(220, 0%, 100%, 1);
      border-radius: 5px;
      box-shadow: 0 2px 4px 2px rgba(248, 248, 248, 0.76);
      margin-bottom: $size--padding--module;
      padding: $size--padding--module--interior;
    }
  }
}
