@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
div.issues-map-container {
  height: 66vh;
  padding: 10px 0 24px 0;

  div.issues-map-wrapper {
    height: 100%;
    width: 100%;
  }

  div.loading-indicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    top: -100%;
    z-index: 999;

    svg {
      transform: scale(1.5);
    }
  }
}
