@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.form-item {
  &-hint,
  &-label {
    color: $color--input-label;
    display: block;
    font-size: 10px;
    line-height: 18px;
  }

  &-error-message {
    color: $color--input-error;
    font-size: 9px;
    font-weight: $font-weight-regular;
    margin-top: 4px;
  }

  &-hint {
    font-weight: $font-weight-regular;
    margin-top: 4px;
  }

  &-label {
    font-size: 12px;
    font-weight: $font-weight-medium;
    margin-bottom: 5px;
  }
}

.form-item + .form-item {
  margin-top: 30px;
}
