@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.cn--nav-prime {
  * {
    box-sizing: border-box;
  }
  display: inline-block;
  nav {
    margin-left: 20px;
    display: inline-block;
  }
  a {
    font-size: calc(16 / $size-font-root) * 1rem;
    height: $size-nav-menu-bar-height;
    display: inline-block;
    color: $color-nav-menu-item-text;
    padding: 0.25 * $size-nav-menu-bar-height 16px;
    line-height: calc(21 / $size-font-root) * 1rem;
    text-decoration: none;

    &:hover {
      background-color: $color-nav-menu-item-bg-hover;
    }
    &.active {
      background-color: $color-nav-menu-item-bg-active;
      font-weight: $font-weight-medium;
      color: $color--white;
    }
  }
}
@media (max-width: $size-viewport-small) {
  .cn--nav-prime {
    nav {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}
