@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_client-credentials {
  position: relative;

  .ExUi_settings-container {
    padding-bottom: 0;
  }

  a.breadcrumb-child {
    &.active {
      color: inherit;
      cursor: default;
      display: inline;
    }

    &.hide {
      display: none;
    }
  }

  a.breadcrumb-parent {
    color: #1157b0;
    cursor: pointer;

    &.active {
      color: inherit;
      cursor: default;
    }
  }
}
