@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
span.icn--dashboard-compliance-arrow {
  margin-left: 8px;
  margin-right: 8px;
  vertical-align: middle;
  transform: scale(0.7);
  background-position: -510px -270px;
  width: 12px;
  height: 17px;
}

span.breadcrumb-text {
  font-family: $font-family-primary-proportional;
  font-size: 20px;
  color: #475a6c;
  font-weight: $font-weight-medium;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
}
