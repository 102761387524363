@import './mixins';
@import './design-system/colors';
@import './design-system/fonts';
@import './design-system/spacing';
@import './design-system/shadows';

//Root Sizes
$size-font-root: 14;
$size-font-root-browser: 16;
$size--sidebar-width: 252px;

//Layout Variables
$size-viewport-small: 960px;
$size--padding--module: 16px;
$size--padding--module--interior: $component-spacing-l;

//region Size Variables
$size-nav-menu-bar-height: calc(40 / $size-font-root) * 1rem;
$size-nav-menu-meta-panel-width: 300px;
$size-nav-menu-meta-panel-icon-spacing: 4px;
$size-nav-side-panel: 18rem;
$size-nav-side-panel-filter: $size-nav-side-panel;
$size-nav-side-panel-categories: $size-nav-side-panel;
//endregion

$radius--panel: 4px;
$radius--button: 3px;

// Color Vars
// dont use, slowly replacing these with new vars below
$color--text-1: $color--gray-100;
$color--text-2: $color--gray-90;
$color--text-3: $color--gray-70;
$color--text-4: $color--gray-60;
$color--text-5: $color--white;
$color--text-6: $color--gray-80;
$color--text-link: $color--blue-sf;
$color--text-link-hover: $color--blue-oakland;
$color-text-1-hover: adjust_color(
  $color--text-1,
  $saturation: 10%,
  $lightness: 10%
);
$color-bg-detail-side-panel: $color--red-10;

//text - Use these, new vars we are now using instead of above
$color--txt-white: $color--white;

$color--h1-txt-header: $color--gray-100;
$color--h2-txt-secondary: $color--gray-90;
$color--h3-txt-tertriary: $color--gray-90;

$color--txt-primary: $color--gray-100;
$color--txt-secondary: $color--gray-90;
$color--txt-tertriary: $color--gray-80;
$color--txt-inactive: $color--gray-70;
$color--txt-disabled: $color--gray-60;
$color--txt-placeholder: $color--gray-50;
$color--txt-muted: $color--gray-40;

$color--btn-txt-inactive: $color--gray-70;
$color--btn-txt-disabled: $color--gray-60;

$color--btn-primary-blue: $color--blue-60;
$color--btn-primary-blue-hover: $color--blue-70;

$color--btn-secondary-bg: $color--blue-60; // dont use yet
$color--btn-secondary-bg-hover: $color--blue-60; // dont use yet

$color--icon-action-active: $color--blue-sf;
$color--icon-inactive: $color--gray-70;
$color--icon-disabled: $color--gray-60;

$color--txt-link-btn-active: $color--blue-sf;
$color--txt-link-active: $color--blue-sf;

$color--success: $color--green-40;
$color--error: $color--red-60;
$color--border--error: $color--error;
$color--text--error: $color--error;
$color--bg--error: $color--white;
$color--bg--error--secondary: $color--red-10;

$color-border-panel: $color--blue-10;

// end blues
$color-text-select-list-item-active: $color--blue-oakland;

//region Semantic Color Definitions
// If you find yourself naming a variable with a color name, you are doing it wrong!
// Color variable names should only refer to the semantic meaning or the element context in which they are used.
// Naming convention is big endian: $color-{semantic meaning or element name}-{bg|text|border}-{modification state: hover|active|selected|etc}
// HSLA Color definitions are preferable to Hex or RGBA, because HSLA is easier to reason about color-theory-wise (The chrome dev console can convert them easily.)

// region Settings List
$color-text-settings-list-details: $color--gray-90;
// endregion Settings List

// region Api Connectors Select Source Type
$color-text-api-connectors-select-source-type-instructions: $color--gray-80;
$color-text-api-connectors-select-source-type-label: $color--gray-80;
// endregion Api Connectors Select Source Type

// region Associated Asset
$color-associated-asset-reference-reason-text: $color--gray-80; // todo - jeff check after adding iprangev2 feature flag
// endregion Associated Asset

// region Service Asset Cert Details
$color-service-asset-cert-details-reason-text: $color--gray-80;
// endregion Service Asset Cert Details

// region Full Column Config
$color-full-column-config-column-option-text: $color--gray-90; // /assets/domains can be verified by searching for ExUi_available-columns__column-option and checking color
$color-full-column-config-classification-column-group-header-text: $color--gray-80;
$color-full-column-config-no-columns-text: $color--gray-80;
$color-full-column-config-scroll-bg: $color--gray-60;
// endregion Full Column Config

// region Checkbox
$color-checkbox-bg-selected: $color--blue-sf; // QA - /assets/ip-ranges check any checkbox's bg color in the table
// endregion Checkbox

// region Config Column
$color-config-column-border: $color--gray-30; // QA - /assets/ip-ranges open the filters and check ExUi_config-column-footer element for border color
$color-drag-icon-border: $color--gray-40;
// endregion Config Column

// region Keyset Pagination
$color-keyset-pagination-text: $color--gray-60;
// endregion Keyset Pagination

// region Service Classification Evidence
$color-service-classification-text: $color--gray-60;
$color-service-classification-more-details-text: $color--gray-80;
// endregion Service Classification Evidence

// region Pill List
$color-pill-list-title-text: $color--gray-80;
$color-pill-list-text: $color--steel-blue-90;
$color-pill-list-bg: $color--steel-blue-10;
$color-pill-list-bg-hover: $color--gray-10;
// endregion Pill List

// region Discovery Type Row
$color-discovery-type-details-text: $color--gray-80;
// endregion Discovery Type Row

//region Navigation
$color-nav-menu-bar-bg: $color--steel-blue-90; // QA - check the bg color of the menubar ie the main nav
$color-nav-menu-bar-bg-2: $color--white;
//If you change the item-text color, also update the SVG sprites to match for the icons at the right of the menu bar
$color-nav-menu-item-text: $color--steel-blue-30;
$color-nav-menu-item-bg-hover: $color--steel-blue-80; // QA - check the hover in on a nav item
$color-nav-menu-item-bg-active: $color--steel-blue-70;
$color-nav-menu-tenant-text: $color--white;
// check the active state of the navitem
//endregion Navigation

//region Sub-Navigation
//TODO: to follow our naming conventions we should make this color-text-sub-nav...
$color-sub-nav-text-link: $color--gray-90; // QA - check text color of the subnav on http://localhost:8080/assets/ip-ranges
$color-sub-nav-text-link-hover: $color--blue-oakland;
$color-sub-nav-text-link-active: $color--gray-90;
$color-sub-nav-border-bottom-hover: $color--blue-70;
$color-sub-nav-border-bottom-active: $color--blue-60;
$color-bg-sub-nav: $color--gray-80; // QA - can't find where this is used
//endregion Sub-Navigation

//region print style sheet colors
$color--text-print1: $color--black;
$color--border-print: $color--gray-90;
//endregion print style sheet colors

//region Form Colors
$color-form-required: $color--red-60;
//endregion Form Colors

//region Detail Page Colors
$color--kv--label--text: $color--gray-60; // QA - Check the label with the class key-value-evidence__label on the issues detail page.
$color--kv--value--text: $color--gray-90; // QA - Check the label with the class key-value-evidence__va on the issues detail page.
$color--kv--value--text--muted: $color--gray-70; // QA - Check the l  abel with the class key-value-evidence__va on the issues detail page.
$color--kv--header--text: $color--gray-20; // QA - Check the label with the class key-value-evidence__va on the issues detail page.
//endregion Detail Page Colors

$color--text--header: $color--gray-100; // these might be not used anymore, ipDetailsV2 is ga
$color--text--header--2: $color--gray-90; // these might be not used anymore, ipDetailsV2 is ga
$color--text--header--muted: $color--gray-70; // these might be not used anymore, ipDetailsV2 is ga
$color--text--desc: $color--gray-80;
//endregion Flexible Details Page Colors

//region Component Specific Colors
$color--ellipsis-dots--text: $color--white;
//endregion Component Specific Colors

//region Toast
$color--toast--title: $color--gray-100;
$color--toast--text: $color--gray-90;
$color--toast--bg: $color--white;
$color--toast--neutral: $color--blue-sf;
$color--toast--success: $color--green-40;
$color--toast--error: $color--red-60;

//endregion Toast

//region Async action status colors
$color-complete: hsla(140, 67%, 24%, 0.8);
$color-notice: rgba(20, 48, 102, 0.8);
$color--error: $color--red-60;
$color--border--error: $color--error;
//endregion

//region UI widget colors
$color-bg-border-dropdown: $color--white;
$color-bg-toolbar: $color--gray-20;
$color-bg-interaction: $color--gray-10;
$color-bg-interaction-stripe: adjust_color(
  $color-bg-interaction,
  $lightness: -2%
);
$color-bg-interaction-footer: adjust_color($color-bg-toolbar, $lightness: 6%);
$color-bg-footer: $color--white;
$color--bg--nav-2: $color--gray-80;

//region React Select Customization Colors
$color--text--toolbar-select-light: $color--white;
//endregion React Select Customization Colors

//endregion UI widget colors

//region Map Colors
$color-bg-leaflet: $color--gray-90;
$color-bg-leaflet-water: $color--black;
$color-text-demo-banner: $color--gray-100;
$color-text-contrast-map: hsla(220, 0%, 100%, 0.5);
//endregion Map Colors

//endregion Semantic Color Definitions

//cert details colors TODO: move to quill
$color--analysis-summary--overview--not-trusted: $color--orange-40;
$color--analysis-summary--overview--trusted: $color--green-60;
$color--analysis-value--background--not-trusted: $color--orange-30;
//endcert details colors

//region replace-these-colors
//TODO: eventually all these colors need to be migrated to semantic color names;

$hi-pink: hsla(354, 100%, 42%, 1);
$hi-orange: #f48e16;
//TODO: rename this to reflect how it is used
$hi-yellow: hsla(42, 100%, 49%, 1);
$hi-green: hsla(137, 63%, 41%, 1);

//endregion replace-these-colors

//region Buttons Colors
$color--bg--button--l1: $color--blue-70;
$color--bg--button--l2: $color--white;
$color--bg--button--l3: $color--white;
$color--border--button: $color--blue-70;
$color--border--button--l2: $color--gray-20;
$color--button-disabled-bg: $color--gray-20;
$color--icn--button--disabled: $color--gray-60;
$color--text--button--disabled: $color--text-4;
$color--text--button--l1: $color--white;
$color--text--button--l2: $color--gray-90;
$color--text--button--l3: $color--blue-60;
//endregion Buttons

//region React Select default skinning
$color--text--toolbar-select: $color--blue-60;
$color--text--rs-standard-light: $color--text-1;
$color--bg--selected-menu-item: adjust_color(
  $color--bg--nav-2,
  $lightness: 40%
);
//endregion React Select default skinning

//TODO EX-3655: audit use of and remove the dependencies on these colors
$color-background-primary: $color--black;

//TODO EX-2414: replace all font-primary colors w/ text-primary when we depreciate old interface

$color-text-active: $color--white;
$color-bg-secondary-v2-hover: $color--blue-30;

//TODO EX-3655: audit use of and remove the dependencies on these colors
$color-bg-primary-v2: $color--gray-40; // I don't think these are used anymore
$color-bg-primary-v2-hover: $color--gray-50; // I don't think these are used anymore
$color-bg-secondary-v2: $color--gray-60; // I don't think these are used anymore
//TODO EX-3655: audit use of and remove the dependencies on these colors

$color-shadow: $color--blue-10;

//region Global BG colors
$color-bg-body: $color--white;
$color--bg--toolbar: $color--black;
$color-bg-tab-inactive: darken($color-bg-body, 10%);
$color-bg-tag: $color--blue-20;
//TODO change to $color--blue-primary-hover when handleing blues
$color-bg-dropdown: $color--red-10;
$color-bg-dash-panel: $color--red-10;
//endregion Global BG colors

//region Semantic Colors
$color-info-neutral: $color--gray-70;
$color-info-null: $color--gray-60;

//region Severity Colors
$color-critical: hsl(356, 100%, 41%);
$color-warning: hsl(42, 100%, 49%);
$color-routine: hsl(169, 62%, 41%);
//endregion Severity Colors

$color-danger: $color--red-60;
$color-success: $color--green-60;
$color-active: $color--blue-oakland;
$color-text-error: $color-danger;
$color-background-error: lighten($color-text-error, 30%);
$color-interaction: $color--blue-60;
$color-neutral: $color--text-3;

$color-bg-interaction: adjust_color(
  $color-interaction,
  $saturation: -5%,
  $lightness: 50%
);
$color-section-line: $color--black;
$color-bg-table-stripe: $color--white;
//endregion

//region Text Selection Highlight
$color-bg-selection: $color--blue-20;
$color-text-selection: $color--gray-80;
//endregion Text Selection Highlight

//region Buttons
$color-text-button: $color--white; // QA - go to /assets/ip-ranges, check the create custom range button font color
$color-text-button-disabled: $color--gray-70;

$color-btn-neutral: $color--blue-70;
$color-btn-secondary: $color--blue-20;
$color-btn-disabled: $color--blue-10;
$color-btn-toggle: $color--gray-70; // QA - /exposures/on-prem search for toggle-button-item class, then inspect font color
$color-btn-toggle-hover: $color--gray-80; // QA - /exposures/on-prem search for toggle-button-item class, then inspect hover font color
$color-text-btn-toggle-active: $color--white; // QA - /exposures/on-prem search for toggle-button-item class, then inspect active font color
$color-btn-danger: $color-btn-neutral;
$color-btn-success: $color-btn-neutral;

$color--btn-primary: $color--blue-sf;
$color--btn-primary-hover: $color--blue-oakland;
$color--btn-primary-text: $color--white;

$color--btn-disabled: $color--blue-sf;
$color--btn-disabled-hover: $color--blue-oakland;
$color--btn-disabled-text: $color--white;

$color--active-link-border: $color--gray-30;
// new DS colors
// new DS colors
$color--btn-primary--bg: $color--blue-sf;
$color--btn-primary--hover: $color--blue-oakland;
$color--btn-primary--text: $color--white;

$color--btn-secondary--bg: $color--white;
$color--btn-secondary--border: $color--gray-30;
$color--btn-secondary--text: $color--blue-sf;
$color--btn-secondary--border-hover: $color--blue-oakland;
$color--btn-secondary--bg-hover: $color--blue-10;

$color--btn-outline-muted--bg: $color--white;
$color--btn-outline-muted--border: $color--gray-30;
$color--btn-outline-muted--text: $color--blue-sf;
$color--btn-outline-muted--border-hover: $color--blue-oakland;
$color--btn-outline-muted--bg-hover: $color--blue-10;

$color--btn-link--bg: $color--white;
$color--btn-link--text: $color--blue-sf;
$color--btn-link--border: $color--blue-sf;
$color--btn-link--hover-bg: $color--blue-20;
$color--btn-link--disabled-text: $color--gray-60;

$color--btn-disabled--bg: $color--gray-60;
$color--btn-disabled--hover: $color--blue-oakland;
$color--btn-disabled--text: $color--white;

$color--btn-with-link-border: $color--gray-30;

$color--btn-destructive--bg: $color--red-60;
$color--btn-destructive--hover: $color--red-50;
$color--btn-destructive--text: $color--white;
//endnew DS colors
//endregion Buttons

//region Forms
$color-form-input-border: $color--gray-30;
$color-form-input-border-focus: $color--blue-sf;
$color-form-text-input: $color--txt-secondary;
$color-form-bg-input: $color--white;
$color-form-border-unsaved: $color--yellow-40;
//endregion forms

//region input fields
$color--input-border: $color--gray-30;
$color--input-background: $color--white;
$color--input-inactive-placeholder: $color--gray-50;
$color--input-text: $color--gray-100;
$color--input-hint: $color--gray-90;
$color--input-label: $color--gray-60;
$color--input-border-focus: $color--blue-sf;
$color--input-error: $color--red-60;

//endregion input fields

//region Scrollbars
$color-scroll-thumb: rgba(0, 0, 0, 0.4);
$color-scroll-thumb-hover: hsla(220, 50%, 50%, 0.8);
$color-scroll-track: rgba(0, 0, 0, 0.2);
//endregion Scrollbars

//region filtering widgets
//TODO: compute/define these colors from a set of base colors
$color-bg-filter: $color--gray-60;
$color-text-select-list-item: $color--text-1;
$color--text-select-list-item-active: $color--blue-active;
$color-text-select-list-item-hover: $color--blue-hover;
$color-bg-select-list-item: $color--blue-30;
$color-bg-select-list-item-active: $color--blue-20;
$color-bg-select-list-item-hover: $color--blue-70;
//endregion

//region Pagination Colors
$color-text-pagination-select: $color--gray-90;
$color-border-pagination-select: $color--gray-30;
$color-border-pagination-select-hover: $color--blue-70;
$color-border-pagination-select-active: $color--blue-60;
$color-text-pagination-range: $color--gray-80;
$color-bg-pagination-button: $color--white;
//endregion Pagination Colors

//region Table Colors
$color-border-table-primary: $color--gray-60;
$color-border-bottom-table-secondary: $color--gray-30;
$color-text-and-more-tooltip: $color--blue-70;
$color-text-table: $color--gray-90; // QA - check the /assets/ip-ranges page for rt-th class on table headers
$color--table--row--bg--active: $color--blue-10; // QA - /issues click on a table row, you will go to a details page, then press the browser back button and check the tr focused class
$color--table--row--bg--hover: $color--blue-20; // QA - /issues click on a table row, you will go to a details page, then press the browser back button and check the tr focused class
$color--menu-item--bg--active: $color--blue-10; // QA - /issues click on a table row, you will go to a details page, then press the browser back button and check the tr focused class
$color--menu-item--bg--hover: $color--blue-20; // QA - /issues click on a table row, you will go to a details page, then press the browser back button and check the tr focused class
$color-text-sub-row-tags: $color--gray-100; // QA - /services look at the tags below the row name "DNS SERVER" for the class of tag, inspect that element. search for .services-table .tag
//endregion Table Colors

//region Panel Colors
$color--border-panel: $color--blue-10; // QA - /exposures/on-prem check the cn--collapsible-panel class for box-shadow color
$color-bg-panel: $color--blue-10;
//endregion Panel Colors

//region Modal Colors
$color-text-modal-header: $color--text-1; // QA - /assets/ip-ranges click on Create Custom Range. search for expander-modal-header class, check color matches
$color-bg-modal: $color-bg-body; // QA - /assets/ip-ranges click on Create Custom Range. search for expander-modal-container class, check white bg color
$color-bg-modal-header: $color-bg-body; // QA - /assets/ip-ranges click on Create Custom Range. search for expander-modal-header class, check white bg color
$color-shadow-modal: $color--steel-blue-90;
$color-icon-modal-header: $color--gray-70;
//endregion Modal Colors

//region Tabbed Panel Colors
$color-border-tabbed-panel: $color--border-panel;
$color-bg-tabbed-panel: $color--blue-40;
$color-text-tabbed-panel: $color--text-1;
$color-bg-tabbed-panel-active: scale_color(
  $color-bg-tabbed-panel,
  $lightness: 25%
);
$color-text-tabbed-panel-active: $color--text-1;
$color-bg-tabbed-panel-hover: scale_color(
  $color-bg-tabbed-panel,
  $lightness: 35%
);
$color-text-tabbed-panel-hover: $color--text-1;
//endregion Tabbed Panel Colors

//region Settings Panel colors
$color-border-settings-panel: $color--red-10;
$color-bg-settings-panel-menu: scale_color(
  $color-bg-modal-header,
  $lightness: 70%
);
$color-bg-settings-panel-menu-hover: scale_color(
  $color-bg-modal-header,
  $lightness: 90%
);
//endregion Settings Panel colors

//region Popover Menu colors
$color-bg-popover-menu: $color--green-100;
$color-border-popover-menu: $color--green-100;
// endregion Popover Menu colors

//region Tooltip colors
$color--tooltip--text: $color--white;
$color--tooltip--bg: $color--gray-100;
$color--tooltip--link: $color--blue-30;
$color--ellipsis-tooltip--bg: $color--gray-70;
// end region Tooltip colors

//region Dropdown colors
$color-bg-dropdown-2-hover: $color--blue-80;
// end region Dropdown colors

// region Status Bar colors
$color-text-status-bar-status: $color--gray-100;
$color-bg-status-bar-pill: $color--steel-blue-10;
$color-text-status-bar-pill: $color--steel-blue-50;
$color-text-status-bar-descriptor: #5a738b;
// end region Status Bar colors

// region Details Panel colors
$color-bg-details-panel-top-bar: $color--white;
$color-border-details-panel-top-bar: $color--gray-30;
// end region Details Panel colors

$sprite-file-width: 800px;
$input-border-width: 1px;

//region Colors
$color--link--nav: $color--white; // can't find usage
$color--bg--main: $color--white;
$color--bg--details: $color--white; // can't find usage
$color--bg--filter-bar: $color--gray-05;
$color--filter-bar--label: $color--gray-60;
$color--bg--menu-item--hover: $color--blue-20;
$color--border--menu-item--hover: $color--blue-20;
$color--border--tables: $color--red-10;
$color--border--panel: $color--gray-30;
$color--bg--count-bubbles: $color--red-10;

$color--bg--selected-menu-item: $color--blue-10;
$color--bg--focused-menu-item: $color--blue-20;

// Dashboards Chart Colors
$color--dashboards--blue-dark: hsla(218, 69%, 30%, 1);
$color--dashboards--page-header: $color--gray-90;
$color--dashboards--chart-title-gray: $color--gray-100;
$color--dashboards--chart-no-data-h3-dark: $color--gray-100;
$color--dashboards--chart-no-data-p-light: $color--gray-70;
$color--dashboards--chart-no-data-box-shadow: hsla(0, 0%, 93%, 1);
$color--dashboards--chart-no-data-border: hsla(0, 0%, 95%, 1);

// Dashboard Chart Title Colors
$color--dashboards--chart-title-gray: hsla(218, 14%, 15%, 1);
$color--dashboards--chart-title-background-gray: hsla(210, 39%, 88%, 0.27);
$color--dashboards--chart-title-blue: hsla(218, 69%, 24%, 1);
$color--dashboards--chart-title-background-blue: hsla(212, 52%, 95%, 1);
$color--dashboards--chart-title-red: hsla(0, 71%, 33%, 1);
$color--dashboards--chart-title-background-red: hsla(348, 100%, 98%, 1);
$color--dashboards--chart-title-light-gray: hsla(0, 100%, 100%, 1);
$color--dashboards--chart-title-background-light-gray: hsla(
  209,
  21%,
  45%,
  0.45
);

// Dashboards Map Overview Colors
$color--dashboards--map-overview--legend--bg: $color--gray-100;
$color--dashboards--map-overview--legend--hover-bg: hsla(214, 14%, 29%, 1);
$color--dashboards--map-overview--legend--active-bg: hsla(209, 21%, 45%, 1);
$color--dashboards--map-overview--legend--text: $color--white;
$color--dashboards--map-overview--icon--default-bg: hsla(208, 26%, 64%, 1);
$color--dashboards--map-overview--icon--critical-high-bg: hsla(2, 75%, 52%, 1);
$color--dashboards--map-overview--icon--medium-bg: hsla(34, 87%, 66%, 1);
$color--dashboards--map-overview--icon--low-bg: hsla(212, 100%, 60%, 1);
$color--dashboards--map-overview--icon--corporate-bg: $color--purple-50;
$color--dashboards--map-overview--icon--remote-bg: $color--blue-50;
$color--dashboards--map-overview--tooltip-bg: hsla(200, 60%, 2%, 1);
$color--dashboards--map-overview--tooltip--text: $color--white;

// IconBanner Colors
$color--icon-banner--bg: $color--blue-20;
$color--icon-banner--text: $color--gray-100;
$color--icon-banner--icon--bg: $color--indigo-20;

// Banner Colors
$banner-default-border-color: $color--gray-90;
$banner-primary-border-color: $color--blue-primary;
$banner-success-border-color: #00b895;
$banner-warning-border-color: #faaf00;
$banner-danger-border-color: #c70f18;

$banner-default-bg-color: #eff2f7;
$banner-primary-bg-color: #eff2f7;
$banner-success-bg-color: #e6f7f4;
$banner-warning-bg-color: #fff5d8;
$banner-danger-bg-color: #fcf3f3;

$banner-default-text-color: #eff2f7;
$banner-primary-text-color: #0066f5;
$banner-success-text-color: #00856c;
$banner-warning-text-color: #8e6300;
$banner-danger-text-color: #c70f18;

// <SelectList />
$color--facet-select--text: $color--gray-90;
$color--facet-select--selected-bg: $color--blue-active;
$color--facet-select--focused-bg: $color--blue-hover;
$color--facet-select--active-border: $color--blue-60;
$color--facet-select--hover-border: $color--blue-70;
$color--facet-select--border: $color--gray-30;
$color--facet-select--alloptionsbutton-border: $color--gray-30;

//switch
$color--slider--border: hsla(210, 17%, 91%, 1);
$color--slider--border--hover: hsla(214, 82%, 38%, 1);
$color--slider--on--bg: $color--green-50;
$color--slider--on--border--hover: $color--green-60;
$color--slider--off--bg: $color--red-60;
$color--slider--off--border--hover: $color--red-70;
$color--slider--switch--bg: $color--white;
$color--slider--text: $color--white;
//endswitch

// region Basic Map colors
$color-bg-basic-map-marker-default: $color--dashboards--map-overview--icon--remote-bg;
$color-bg-basic-map-marker-remote: $color--dashboards--map-overview--icon--remote-bg;
$color-bg-basic-map-marker-corporate: $color--dashboards--map-overview--icon--corporate-bg;
// end region Basic Map colors
