@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.custom-range-modal {
  .expander-modal-content {
    padding: pxToRem(16);
  }

  .expander-modal-container {
    max-width: pxToRem(600);
  }
}

.custom-range-modal-content {
  input.mod--disabled {
    color: $color--text-3;
  }

  .actions {
    text-align: right;

    button {
      margin-left: pxToRem(15);
    }
  }

  .cn--parent-ips {
    > span {
      display: inline-block;
      vertical-align: top;

      &:first-child {
        width: 160px;
      }
    }
    margin-bottom: 1rem;
  }

  .ExUi_DataSection__dataHeader {
    border: 0;
    font-weight: $font-weight-medium;
  }

  .ExUi_DataSection {
    margin-bottom: 0;

    .ExUi_DataSection__dataContent {
      padding-top: 0.5rem;

      &.number-of-ips {
        padding-top: 1rem;
      }
    }

    .ExUi_DataSection__dataHeader {
      border-bottom-width: 0;
    }
  }

  .error-message-container {
    margin-top: 10px;
  }
  .error-message-container > div {
    background: $color-background-error;
    border-left: 4px solid $color-critical;
    color: $color--text-1;
    font-size: pxToRem(12);
    padding: 4px 5px;
    font-weight: $font-weight-regular;
    margin-bottom: 4px;
  }

  .input-instructions {
    color: $color--text-3;
    font-size: pxToRem(12);
    line-height: 1.4em;
    margin-bottom: 1rem;
    margin-top: pxToRem(15);

    > * {
      margin-bottom: pxToRem(5);
    }
  }

  .ipInput {
    width: pxToRem(120);

    &.danger {
      border-color: $color-danger;
    }

    &.success {
      border-color: $color-success;
    }
  }

  .line-group {
    display: flex;
    > * {
      margin-right: pxToRem(15);

      &:last-child {
        margin-right: 0;
      }
    }

    .ExUi_DataSection {
      display: inline-block;
      vertical-align: baseline;
      white-space: nowrap;
    }

    .error {
      max-width: 55%;
    }

    .ip-input-dash {
      margin-bottom: 7px;
      align-self: flex-end;
    }
  }
}
