@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
div.networks-legend {
  width: 110px;
  color: $color--dashboards--map-overview--legend--text;
  border-radius: 2px;
  margin: 25px !important;
  background: $color--dashboards--map-overview--legend--bg;
  padding: 5px 0;
  font-family: $font-family-primary-proportional;

  div.network-type-section,
  div.connection-size-section {
    margin-bottom: 5px;
    overflow: hidden;

    h3 {
      color: $color--dashboards--map-overview--legend--text;
      font-family: $font-family-primary-proportional;
      font-size: 9px;
      font-weight: $font-weight-regular;
      margin: 0;
      padding-top: 5px;
      padding-left: 10px;
    }

    div.legend-entry {
      padding: 3px 0 3px 10px;
      font-size: 9px;
      font-weight: $font-weight-light;
      display: inline-flex;
      width: 100%;
    }
  }

  div.network-type-section {
    div.legend-entry:hover {
      cursor: pointer;
      background: $color--dashboards--map-overview--legend--hover-bg;
    }
  }
}

div.legend-title {
  margin-top: auto;
  margin-bottom: auto;
  line-height: 16px;
}

div.legend-icn {
  border-radius: 50%;
  margin: auto 5px;

  &.extra-small {
    height: 6px !important;
    width: 6px !important;
    margin: auto 12px;
  }

  &.small {
    height: 10px !important;
    width: 10px !important;
    margin: auto 10px;
  }

  &.medium {
    height: 14px !important;
    width: 14px !important;
    margin: auto 8px;
  }

  &.large {
    height: 17px !important;
    width: 17px !important;
    margin: auto 7px;
  }

  &.extra-large {
    height: 21px !important;
    width: 21px !important;
  }

  &.default--color {
    background-color: $color--dashboards--map-overview--icon--default-bg;
  }

  &.remotenetwork--color {
    background-color: $color--dashboards--map-overview--icon--remote-bg;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

  &.corporatenetwork--color {
    background-color: $color--dashboards--map-overview--icon--corporate-bg;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

  &.unselected {
    opacity: 0.8 !important;
  }

  &.selected {
    opacity: 1 !important;
  }
}
