@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.splashPageImage {
  position: relative;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  background: -moz-linear-gradient(
    90deg,
    rgba(0, 36, 61, 1) 0%,
    rgba(11, 43, 65, 1) 75%,
    rgba(0, 36, 61, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(0, 36, 61, 1) 0%,
    rgba(11, 43, 65, 1) 75%,
    rgba(0, 36, 61, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(0, 36, 61, 1) 0%,
    rgba(11, 43, 65, 1) 75%,
    rgba(0, 36, 61, 1) 100%
  );
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}

// TERMS AND AGREEMENTS

.grayed {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 20;
  cursor: pointer;
  font-size: 2.5em;

  .modal {
    width: 1000px;
    margin: 5vh auto;
    height: 90vh;
    background: $color-bg-primary-v2;
    overflow-y: auto;

    .agree-section {
      position: fixed;
      top: 5vh;
      width: inherit;
      padding: 2vh 3vw 2vh 3vw;
      background-color: $color-bg-primary-v2;
      font-size: 0.5em;
      border-bottom: solid #999 1px;
      user-select: none;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      form {
        padding: 5px 10px;
        float: left;
      }
      input {
        margin: 10px 20px 0 0;
        user-select: all;
        float: left;
      }
      .input-text {
        font-size: 0.8em;
        width: 400px;
        float: left;
        margin-top: 0;
      }
      .accept-btn {
        float: right;
        margin-top: 10px;
      }
    }
    .m-terms {
      padding: 10vh 7vw;
      z-index: 30;
      cursor: auto;
    }
    p {
      font-size: 0.45em;
      line-height: 1.3em;
      margin-top: 8px;
    }
    h2 {
      font-size: 1em;
      font-weight: $font-weight-regular;
      padding: 0;
      margin: 30px 0 5px -3px;
      span {
        font-size: 0.6em;
      }
    }
    h3 {
      font-size: 0.65em;
      font-weight: $font-weight-regular;
      padding: 0;
      margin: 60px 0 0 0;
      &:nth-of-type(1) {
        margin: 5px 0 0 0;
      }
      span {
        font-size: 0.8em;
        margin-left: -25px;
      }
    }
    h4 {
      font-size: 0.45em;
      font-weight: $font-weight-regular;
      padding: 0;
      margin: 15px 0 0 0;
      span {
        font-size: 0.8em;
        margin-left: -28px;
      }
    }
    h5 {
      font-size: 0.4em;
      font-weight: $font-weight-light;
      line-height: 1.3em;
      padding: 0 0 0 30px;
      margin: 0;
      &:nth-of-type(1) {
        padding: 0;
        margin: 5px 0 40px 0;
      }
    }
    span {
      font-weight: 600;
      margin-right: 8px;
    }
  }
}
