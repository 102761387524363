@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.dashboards-v2-component {
  .cn--prime {
    background-color: hsl(223, 54%, 97%);
    width: auto;
    flex-grow: 1;
    overflow: hidden;

    div.dashboards--pinned-top-level {
      position: sticky;
      top: 0;
      z-index: 1;

      div.dashboards--xasa-banner {
        background: $color--white;

        div.dashboards--xasa-message {
          max-width: 800px;
          line-height: $line-height-m;

          button,
          a {
            color: $color--blue-50;
            border: none;
            background: none;
            padding: 0;
            font-size: inherit;
            font-family: inherit;
            cursor: pointer;
            text-decoration: none;
            font-weight: $font-weight-medium;
          }
        }
      }
    }

    .filter-bar {
      background: white;
      border-bottom: 1px solid #e4e8ec;
    }
  }
}

.dashboards--collapsible-filter-panel {
  z-index: 200;
  width: $size-nav-side-panel-filter;
  height: calc(100vh - #{$size-nav-menu-bar-height});
}
