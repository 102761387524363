@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi {
  &_input-validation-error {
    border-left: 6px solid $color--error;
    box-shadow: rgba(0, 0, 0, 0.14) 1px 2px 5px;
    color: $color--error;
    margin: 10px 20px 0 0;
    padding: 10px 20px 10px 14px;

    &__msg {
      font-size: 14px;
      font-weight: $font-weight-light;
    }
  }
}
