@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
$arrow-color: $color--gray-40;

.triangle {
  border-bottom: 20px solid transparent;
  border-top: 20px solid transparent;
  height: 0;
  width: 0;

  &__left {
    border-right: 20px solid $arrow-color;
  }

  &__right {
    border-left: 20px solid $arrow-color;
  }
}

.behavior-details-arrow {
  text-align: center;

  .flow-arrow {
    > div {
      display: inline-block;
      vertical-align: middle;
    }

    .shaft {
      background-color: $arrow-color;
      height: 20px;

      &.small {
        width: 10px;
      }

      &.large {
        width: 80px;
      }
    }
  }

  .unknown-arrows {
    > * {
      display: inline-block;
      vertical-align: middle;
    }

    span {
      color: $color--gray-90;
      font-size: 36px;
      font-weight: $font-weight-medium;
      margin: 0 15px;
    }
  }
}
