@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
/* stylelint-disable */
.ExUi {
  &_api-connectors-table {
    &__loading {
      display: flex;
      justify-content: center;
      padding-top: 230px;
      pointer-events: none;
      position: absolute;
      width: 100%;
      z-index: 1000;

      svg {
        width: 96px;
      }
    }

    &__btn {
      left: 50%;
      position: absolute;
      top: 100px;
      transform: translate(-50%, -50%);
      z-index: 2;
    }

    .cn--base-table,
    .cn--table {
      height: 60vh;
      width: 100%;

      table {
        .ExUi_api-connectors-table__no-data {
          color: $color--gray-90;
          font-size: 12px;
          font-weight: $font-weight-medium;
          line-height: 14px;
          top: 24px;
        }

        .rt-th {
          color: $color--gray-100;
          font-size: 13px;
          font-weight: $font-weight-medium;
        }

        tbody {
          .rt-tr {
            height: 55px;

            &:hover {
              .connector-delete {
                visibility: visible;
              }
            }
          }

          td {
            color: $color--gray-90;
            font-size: 12px;

            svg {
              vertical-align: middle;
            }
          }

          .connector-delete {
            align-items: center;
            color: $color--blue-primary;
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            margin-right: 30px;
            visibility: hidden;
          }

          .connector-name {
            color: $color--gray-100;
            font-weight: $font-weight-medium;
          }

          .connector-status {
            &-active,
            &-failed {
              align-items: center;
              display: flex;

              svg {
                position: relative;
                top: -1px;
              }
            }

            &-active {
              color: #247f6f;
            }

            &-failed {
              color: #9a0428;
            }
          }
        }
      }
    }
  }

  &_delete-connector-modal {
    // helps with overriding styles
    &.ExUi_modal {
      align-items: flex-start;
      background-color: rgba(0, 0, 0, 0.15);
      padding-top: 160px;
    }

    &-content-wrapper {
      background-color: $color--white;
      border-radius: 5px;
      box-sizing: border-box;
      min-height: 160px;
      padding: 0 20px;
      width: 430px;
    }

    &-content {
      display: flex;

      &-actions {
        display: flex;
        justify-content: flex-end;
        margin: 25px auto;

        .ExUi_btn:last-child {
          margin-right: 0;
        }
      }

      &-icon-wrapper {
        margin: 40px 20px 0 auto;

        svg {
          transform: scale(2);
        }
      }

      &-text {
        color: $color--steel-blue-30;
        font-size: 13px;
        font-weight: $font-weight-regular;
      }

      &-title {
        color: $color--gray-100;
        font-size: 14px;
        font-weight: bold;
        margin: 40px auto 10px;
      }
    }
  }
}
