@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
$progress-bar-height: 16px;
$progress-bar-border-radius: 8px;

.progress-bar-container {
  background-color: rgba(212, 224, 236, 1);
  border-radius: $progress-bar-border-radius;
  height: $progress-bar-height;
  margin: 0 20px;
  width: 460px;

  .progress-bar-filler {
    background-color: rgba(0, 112, 255, 1);
    border-radius: $progress-bar-border-radius;
    height: $progress-bar-height;

    &.withAnimation {
      transition: width 500ms;
    }
  }
}
