@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.dashboard-download-details {
  details:not([open]) > :not(summary) {
    display: none !important;
  }

  details > summary::-webkit-details-marker {
    display: none;
  }

  details > summary {
    display: none;
    list-style: none;
    position: absolute;

    padding: 6px;

    z-index: 1000;
    outline: none;
    cursor: pointer;
    line-height: 0px;
    border: 0 !important;
    color: white;
    box-shadow: none !important;
    transition: none;
    opacity: 1;

    svg {
      cursor: pointer;
    }
  }

  .expanse-download-summary {
    display: block;
    top: 14px;
    right: 18px;
    &:focus,
    &:hover {
      outline: 1px solid $color--blue-primary-hover;
    }
  }

  .vega-actions {
    position: absolute;
    z-index: 1001;
    top: 43px;
    right: 12px;
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
    padding-top: 8px;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

    background: white;
    animation-duration: 0.15s;
    animation-name: scale-in;
    animation-timing-function: cubic-bezier(0.2, 0, 0.13, 1.5);
    text-align: left;

    button {
      padding: 8px 16px;
      white-space: nowrap;
      text-decoration: none;
      font-family: $font-family-primary-proportional;
      font-weight: $font-weight-regular;
      font-size: 14px;
      color: #323843;
      line-height: 18px;
      border: 0;
      text-align: start;
      background-color: #ffffff;
    }

    button:hover {
      background-color: #f7f7f9;
      color: black;
    }

    &::before,
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
    }

    &::before {
      left: auto;
      right: 14px;
      top: -16px;
      border: 8px solid #0000;
      border-bottom-color: #d9d9d9;
    }

    &::after {
      left: auto;
      right: 15px;
      top: -14px;
      border: 7px solid #0000;
      border-bottom-color: #fff;
    }
  }

  @keyframes scale-in {
    from {
      opacity: 0;
      transform: scale(0.6);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }
}
