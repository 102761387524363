@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
#vg-tooltip-element {
  font-family: $font-family-primary-proportional;
  font-size: $font-size-s;
}

#vg-tooltip-element.custom-theme {
  background-color: $color--tooltip--bg;
  border: 1px solid #f5f5f5;
  color: white;
  max-width: 300px;
  word-wrap: normal;

  & table tr td {
    &.key {
      color: white;
      vertical-align: top;
    }
    &.value {
      color: white;
      font-weight: bold;
    }
  }
}

#vg-tooltip-element.custom-dark-theme {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid #f5f5f5;
  color: #323843;
  max-width: 300px;
  word-wrap: normal;

  & table tr td {
    &.key {
      color: #323843;
      vertical-align: top;
    }
    &.value {
      color: #323843;
      font-weight: bold;
    }
  }
}

table.legend-icn-tooltip {
  display: inline-flex;
  padding: 0 4px;

  tbody > tr {
    line-height: 20px;

    td {
      display: inline-flex;

      &.legend {
        width: auto;
      }

      div.legend-icn {
        width: 10px;
        background-color: #4d9fff;
        height: 10px;
        border: 1px solid white;
        position: relative;
        top: -1px;
        margin: auto 5px auto 0;
      }

      &.count {
        padding-left: 20px;
        float: right;
        font-weight: $font-weight-medium;
      }
    }
  }
}
