// Global Master Layout SCSS
// Just items related to sizing here to keep it tidy please!
// Color and typography rules go into their respective files if they are global.

body {
  margin: 0;
  padding: 0;
}

//region Double Left sidebar panel layout

.cn--grid {
  box-sizing: border-box;

  //region pre-grid compatibility, remove when switching back to grids
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  height: 100%;
  overflow: auto;
  //endregion

  //TODO: once we have all customers using browser versions that are grid-compatible,
  //switch back to grid based layout.
  //display: grid;
  //grid-template-columns: $size-nav-side-panel-filter $size-nav-side-panel-categories 1fr;
  //grid-template-rows: calc(100vh - #{$size-nav-menu-bar-height}) 1fr;
  //align-items: start;
  //align-content: start;
}

.btn--panel-toggle {
  box-sizing: border-box;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

.cn--nav--filter,
.cn--nav--category-menu {
  position: relative;
  overflow: visible;
  //TODO: restore this to use the grid based layout
  //grid-row-start: 1;
  //grid-row-end: 2;
}

.cn--nav--filter {
  //TODO: restore this to use the grid based layout
  //grid-column-start: 1;
  order: 1;
  z-index: 400;
  .nav--tab-panel {
    width: $size-nav-side-panel-filter;
  }
}

.cn--nav--category-menu {
  //grid-column-start: 2;
  order: 2;
  z-index: 300;
  &.cn--filters {
    z-index: 310;
  }
}

.nav--tab-panel {
  //TODO: position fixed used by the grid layout code when we switch back to that
  //position: fixed;
  position: relative;
  height: calc(100vh - #{$size-nav-menu-bar-height});
  .cn--scroll-v {
    box-sizing: border-box;
    padding-bottom: 2rem;
    height: calc(100vh - #{$size-nav-menu-bar-height});
    overflow-y: auto;
    z-index: 500;
  }
  .ctrl--state-toggle {
    background-color: $color-bg-primary-v2;
  }
}

.cn--prime {
  order: 3;
  flex: 1 1;
  min-width: 0;
  //TODO: restore this to use the grid based layout
  //grid-column-start: 3;
  // grid-row-start: 1;
  // grid-row-end: 3;
}

//The state permutations for collapsing the filtering/menu panels
.cn--grid {
  $size-nav-side-panel-collapse-width: calc(18 / 14) * 1rem;
  &.mod--toggle-state--filters {
    //When the filter panel is collapsed
    //TODO: restore this to use the grid based layout
    //grid-template-columns: $size-nav-side-panel-collapse-width $size-nav-side-panel-categories 1fr;
    .cn--nav--filter {
      .nav--tab-panel {
        width: $size-nav-side-panel-collapse-width;
        .cn--scroll-v {
          display: none;
        }
      }
    }
    .cn--prime {
      width: calc(
        100vw - #{$size-nav-side-panel-collapse-width +
          $size-nav-side-panel-categories}
      );
      .leaflet-container {
        width: calc(
          100vw - #{$size-nav-side-panel-collapse-width +
            $size-nav-side-panel-categories}
        );
      }
    }
    &.mod--toggle-state--category-menu {
      //when both are collapsed
      //TODO: restore this to use the grid based layout
      //grid-template-columns: $size-nav-side-panel-collapse-width $size-nav-side-panel-collapse-width 1fr;
      .cn--prime {
        width: calc(
          100vw - #{$size-nav-side-panel-collapse-width +
            $size-nav-side-panel-collapse-width}
        );
        .leaflet-container {
          width: calc(
            100vw - #{$size-nav-side-panel-collapse-width +
              $size-nav-side-panel-collapse-width}
          );
        }
      }
    }
  }
  &.mod--toggle-state--category-menu {
    //when just the category menu is collapsed
    //grid-template-columns: $size-nav-side-panel-filter $size-nav-side-panel-collapse-width 1fr;
    .cn--nav--category-menu {
      .nav--tab-panel {
        width: $size-nav-side-panel-collapse-width;
        .cn--scroll-v {
          display: none;
        }
      }
    }
    .cn--prime {
      width: calc(
        100vw - #{$size-nav-side-panel-filter +
          $size-nav-side-panel-collapse-width}
      );
      .leaflet-container {
        width: calc(
          100vw - #{$size-nav-side-panel-filter +
            $size-nav-side-panel-collapse-width}
        );
      }
    }
  }
  &.mod--toggle-state--category-menu {
    .cn--nav--category-menu {
      .nav--tab-panel {
        .ctrl--state-toggle {
          background-color: transparent;
        }
      }
    }
  }
  &.mod--toggle-state--filters {
    .cn--nav--filter {
      .nav--tab-panel {
        .ctrl--state-toggle {
          background-color: transparent;
        }
      }
    }
  }
}

@media (max-width: $size-viewport-small) {
  .cn--grid {
    //TODO: restore this to use the grid based layout
    //grid-template-rows: calc(100vh - #{2*$size-nav-menu-bar-height}) 1fr;
  }
  .nav--tab-panel {
    height: 100%;
    .cn--scroll-v {
      height: 100%;
    }
  }
}

//endregion Double Left sidebar panel layout

#uirStateVisualizer,
#uirTransitionVisualizer {
  //both to keep these above the master container
  position: relative;
  bottom: 30px;
  z-index: 800;
}
