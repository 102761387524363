@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ip-ranges-tooltip {
  z-index: 99;

  .cn--ip-ranges-tooltip {
    left: -6px;
  }
}

.ip-ranges-cell-size,
.ip-ranges-header-size {
  text-align: right;
}

.left-actions {
  white-space: nowrap;
  display: inline-block;

  .btn-margin {
    margin-right: 10px;
  }
}
