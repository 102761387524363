@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
// stylelint-disable plugin/selector-bem-pattern
// stylelint-disable selector-max-compound-selectors
// TODO: Delete the stylelint-disable lines above when refactoring this file.
// https://qadium.atlassian.net/browse/ROCI-3618
.ExUi_ {
  &FacetSelect {
    &__allOptionButton {
      border-bottom: 1px solid $color--facet-select--alloptionsbutton-border;
      border-radius: 0;
      color: $color--gray-90;
      cursor: pointer;
      height: 48px;
      justify-content: flex-start;
      min-height: 48px;
      padding: 16px;
      position: relative;
      text-align: left;
      top: 1px;
      width: 100%;

      &:hover,
      &:focus {
        background-color: $color--facet-select--focused-bg;
      }

      .icn--check-small {
        margin: 0 4px 0 0;
        vertical-align: middle;
        visibility: hidden;
      }
    }

    &__allOptionButton--selected {
      background-color: $color--blue-10;

      .icn--check-small {
        visibility: visible;
      }
    }
  }
}

// TODO: Refactor BEM selectors to match current stylelint pattern.
.facet-select {
  &.icn--check-small {
    margin: 0 4px 0 0;
    vertical-align: middle;

    &.hidden {
      visibility: hidden;
    }
  }

  &__footer {
    border-top: 1px solid $color--border--panel;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    position: relative;
    top: -1px;

    .apply-button {
      margin-right: 0;
    }
  }

  .facet-select__menu {
    border-radius: 0;
    box-shadow: $shadow-menu;
    margin-top: 2px;
    min-width: 100%;
    width: max-content;
    z-index: 2;
  }

  .facet-select__control {
    border: 1px solid $color--border--panel;
    border-radius: 4px;
    box-shadow: none;
    cursor: pointer;
    max-height: 32px;
    min-height: 32px;

    &:hover {
      border: 1px solid $color--facet-select--hover-border;
    }

    &--menu-is-open {
      border: 1px solid $color--input-border-focus;
    }
  }

  .facet-select__input {
    margin-right: 15px;
  }

  .facet-select__menu-list {
    border-radius: 0;
    min-height: 100px;
    padding: 0;
  }

  .facet-select__multi-value {
    background-color: transparent;
    padding-left: 0;
  }

  .facet-select__multi-value-label {
    color: $color--text--button--l3;
    font-size: 14px;
    font-weight: $font-weight-medium;
    padding-left: 0;
  }

  .facet-select__placeholder {
    color: $color--text-4;
    font-size: 13px;
  }

  .facet-select__option--is-selected {
    background-color: $color--facet-select--selected-bg;
    color: $color--text-1;
  }

  .facet-select__option--is-focused {
    background-color: $color--facet-select--focused-bg;
    color: $color--text-1;
  }

  .facet-select__value-container {
    color: $color--facet-select--text;
    font-size: 14px;
    font-weight: $font-weight-medium;
    height: 30px;
    overflow: hidden;
    padding-left: 13px;
    padding-right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .facet-select-option {
    &__count,
    &__loading {
      float: right;
      padding-right: 12px;
      width: 24px;
    }

    &__count {
      font-size: 12px;
      text-align: right;
    }
  }

  .facet-select__option {
    cursor: pointer;
    height: 48px;
    padding: 16px;

    .facet-select__option__label {
      cursor: pointer;
      flex: 1;
      flex-grow: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:active {
      background-color: $color--blue-10;
      color: $color--text-1;
    }

    &:hover,
    &:focus {
      background-color: $color--facet-select--focused-bg;
    }
  }

  &__dropdown-indicator {
    padding: 0 8px 0 4px;
  }

  &--is-disabled {
    .facet-select__dropdown-indicator {
      svg path {
        fill: $color--gray-40;
      }
    }
  }

  .cn--label {
    align-items: center;
    display: flex !important;
    max-width: 500px;

    .icn--label {
      margin-left: -2px;
      margin-right: 5px;
    }
  }

  .cn--indent {
    padding-left: 1rem;
  }

  .cn--bold {
    font-weight: $font-weight-semibold !important;
  }

  .cn--select-list-search {
    margin: 10px;
  }

  .cn--select-list-search--hide {
    opacity: 0;
    position: absolute;
  }

  .facet-select__group {
    padding: 0;

    .facet-select__group-label {
      align-items: center;
      display: flex;
      height: 48px;
      line-height: 48px;
      overflow: hidden;
      padding-left: 16px;
      text-transform: none;
      width: 100%;

      &:hover,
      &:focus {
        background-color: $color--blue-10;
      }

      .facet-select__group-label__label {
        height: 48px;
        line-height: 48px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: none;
        white-space: nowrap;
        width: 100%;
      }
    }

    .facet-select__group-heading {
      align-items: center;
      color: $color--gray-90;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-weight: $font-weight-medium;
      height: 48px;
      letter-spacing: 0;
      line-height: 18px;
      margin-bottom: 0;
      padding: 0;
    }
  }
}
