@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_cn--right-action-buttons {
  display: flex;

  > * {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.ExUi_cn--title-action-buttons {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;

  > * {
    align-self: flex-end;
    vertical-align: middle;
  }
}

.ExUi_cn--show-paid-level-domains-only-filter {
  align-items: center;
  display: flex;

  > span {
    margin-right: 5px;
  }

  .ExUi_show-paid-level-domains-only-filter {
    max-height: 32px;
    width: 150px;
  }
}
