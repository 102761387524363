@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
// stylelint-disable plugin/selector-bem-pattern
.shadow-scroll {
  position: relative;

  .more-indicator-top,
  .more-indicator-bottom {
    display: block;
    height: 24px;
    left: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity 0.125s ease-in;
    width: 100%;
    z-index: 2;
  }

  .more-indicator-top {
    background: linear-gradient(
      to bottom,
      hsla(0, 0%, 0%, 0.12) 0%,
      hsla(0, 0%, 0%, 0.04) 20%,
      hsla(0, 0%, 0%, 0) 10px
    );
    top: 0;
  }

  .more-indicator-bottom {
    background: linear-gradient(
      to bottom,
      hsla(0, 0%, 0%, 0) 0%,
      hsla(0, 0%, 0%, 0.04) 80%,
      hsla(0, 0%, 0%, 0.12) 100%
    );
    bottom: 0;
  }

  .shadow-scroll__visibility-sensor {
    background-color: $color--blue-10;
    height: 1px;
    opacity: 0%;
    width: 100%;
  }
}
