@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
// stylelint-disable
.ExUi {
  &_pill-list {
    margin: 0 0 30px;

    &__pills {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &__pill {
      background: $color-pill-list-bg;
      border-radius: 2px;
      color: $color-pill-list-text;
      font-weight: $font-weight-semibold;
      margin: 0 4px 4px 0;
      padding: 6px 8px;
    }

    &__none {
      font-weight: $font-weight-light;
      margin-top: 10px;
    }
  }
}
