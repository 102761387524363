@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
$row-padding-left: pxToRem(30);
$cell-padding: pxToRem(5);

.ExUi {
  &_PoliciesTable {
    height: 100%;
  }

  &_PoliciesTable__name {
    font-size: 14px;
  }
}
