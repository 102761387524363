@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
@import '../DragColumn/DragColumn';

.ExUi {
  &_simplified-column-config {
    @include draggable-container;
    background-color: white;
    box-shadow: 0 2px 4px 0 hsla(200, 20%, 10%, 0.2);
    left: 0;
    margin: 0 auto;
    max-height: 500px;
    min-height: 200px;
    overflow: hidden;
    position: fixed;
    right: 0;
    width: 500px;
    z-index: 5;

    * {
      vertical-align: top;
    }

    .ExUi_drag-container {
      max-height: 360px;
    }

    .save-check-box {
      float: left;
      margin-top: 5px;

      input[type='checkbox'] {
        box-shadow: inset 0 0 0 1px hsla(214, 82%, 38%, 1);
        margin-right: 5px;
        margin-top: 1px;

        &:checked {
          background-color: hsla(214, 82%, 38%, 1);
        }
      }
    }

    .icn--settings-btn {
      height: 15px;
    }
  }
}
