@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.behavior-details {
  .behavior-details-content {
    section {
      box-shadow: none;
      padding: 15px;
    }
  }
}

.behavior-details-content {
  background-color: $color--bg--main;
  border-radius: 5px;
  box-shadow: 0 2px 4px 2px rgba(248, 248, 248, 0.76);
  margin: 25px 50px;
  padding: 30px;

  .behavior-details-external,
  .behavior-details-internal {
    section.ip-address-panel {
      box-shadow: 0 2px 6px 0 rgba(48, 48, 65, 0.18);
    }
  }

  .behavior-details-external {
    .ip-address-panel-title {
      color: $color--red-60;
    }
  }

  .behavior-details-internal {
    .ip-address-panel-title {
      color: $color--blue-60;
    }
  }

  .behavior-details-title {
    color: $color--text-6;
    font-size: 18px;
    font-weight: $font-weight-medium;
    margin-bottom: 15px;
    margin-top: 30px;
  }

  .data-list {
    .data-list-item {
      .data-list-item-key,
      .data-list-item-value {
        min-width: 155px;
      }

      .data-list-item-key {
        text-align: right;
      }

      .data-list-item-value {
        > div {
          margin-bottom: 0.5rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .ip-address-panel-title {
    font-size: 18px;
    font-weight: $font-weight-medium;
    margin-bottom: 15px;
  }
}

.behavior-details-name {
  color: $color--text-6;
  font-size: 24px;
  font-weight: $font-weight-medium;
  margin-bottom: 10px;
}

.behavior-details-columns {
  > div {
    display: inline-block;
    margin-top: 20px;
    min-width: 150px;
    vertical-align: top;

    &.flow-arrow-container {
      padding-top: 45px;
    }
  }
}
