@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_api-connectors {
  position: relative;

  .ExUi_settings-container {
    padding: 40px 20px 0 40px;
  }

  .ExUi_settings-title {
    height: 21px;
    color: $color--gray-90;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
  }

  a.breadcrumb-child {
    &.active {
      color: inherit;
      cursor: default;
      display: inline;
    }

    &.hide {
      display: none;
    }
  }

  a.breadcrumb-parent {
    cursor: pointer;

    &.active {
      color: inherit;
      cursor: default;
    }
  }
}
