@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
@import 'node_modules/react-grid-layout/css/styles';
@import 'node_modules/react-resizable/css/styles';

div.dashboard-graph--wrapper {
  height: 100%;
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  box-shadow: 0 4px 14px 0 rgba(155, 166, 187, 0.14);
  border-radius: 4px;
  background: white;
  overflow: hidden;

  &.blue-chart {
    background: $color--dashboards--blue-dark;
  }
}

div.dashboard-graph--wrapper--bar-graph {
  @extend .dashboard-graph--wrapper;
  padding: 25px 50px 25px 25px;
}

div.dashboard-graph--wrapper--metric-panel {
  @extend .dashboard-graph--wrapper;
  padding-bottom: 12px;
  padding-top: 20px;
}

div.dashboard-all-failed-to-load {
  background-color: white;
  padding: 20px;
  border-radius: 5px;

  h3 {
    margin: 0 0 10px 0;
    color: $color--dashboards--chart-no-data-h3-dark;
    font-weight: normal;
  }
  p {
    margin: 0;
    color: $color--dashboards--chart-no-data-p-light;
  }
}

div.dashboard-graph--headline-text {
  height: 25px;
  width: 100%;
  font-size: 1.14286rem;
  line-height: 22px;
  font-family: $font-family-primary-proportional;
  color: #475a6c;
  position: absolute;
  top: -30px;
  white-space: nowrap;
  right: 0;
  font-weight: $font-weight-regular;
}
