@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi {
  &_inline-select-list {
    white-space: normal;

    .inline-select {
      display: block;
      margin: 0;

      input {
        min-width: 0;
      }

      &.mod--has-error {
        .inline-select__control {
          box-shadow: 0 0 0 2px $color--border--error;
        }
      }

      .opt,
      .single-value {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;

        .icn {
          margin-right: 6px;
        }

        .opt-text {
          display: inline-block;
        }
      }

      .single-value {
        .opt-text {
          color: $color--text--toolbar-select;
          font-size: 14px;
          font-weight: $font-weight-medium;
        }
      }

      &__control {
        background: transparent;
        border: 0;
        color: $color--text--toolbar-select;
        cursor: pointer;

        &:hover {
          background-color: $color-btn-secondary;
        }

        &--menu-is-open {
          border-radius: 4px 4px 0 0;
        }
      }

      &__placeholder {
        color: $color--text--toolbar-select;
        font-size: 14px;
        font-weight: $font-weight-medium;
      }

      &__value-container {
        padding: 0 8px;

        .inline-select__input {
          & > input {
            box-shadow: none;
            height: 18px;
          }
        }
      }

      &__value-container {
        .inline-select__input {
          color: $color--text--toolbar-select;
          font-size: 14px;
          position: relative;
        }
      }

      &__menu {
        border-radius: 0 0 4px 4px;
        margin-top: 0;
        z-index: 2;
      }

      &__indicator-separator {
        display: none;
        margin: 0;
      }

      &__single-value {
        color: $color--text--toolbar-select;
        font-weight: $font-weight-medium;
      }

      &__option {
        color: $color--text-1;
      }

      &__dropdown-indicator {
        padding: 0 8px 0 0;
      }

      &__menu {
        .inline-select__menu-list {
          .inline-select__option {
            cursor: pointer;

            &.inline-select__option--is-focused {
              background-color: $color--bg--focused-menu-item;
            }

            &.inline-select__option--is-selected {
              background-color: $color--bg--selected-menu-item;
            }
          }
        }
      }
    }
  }
}
