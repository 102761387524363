@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_FormAnalysis {
  border: 1px solid #eaebeb;

  &__footnote {
    color: #707070;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}
