@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
//stylelint-disable
//TODO: fix this file to follow BEM https://qadium.atlassian.net/browse/TYCHO-4198
.ExUi_asset-details-table {
  border-collapse: collapse;
  margin-bottom: 60px;
  margin-left: 20px;

  thead {
    color: $color--steel-blue-60;
    font-size: pxToRem(12);

    th {
      font-weight: $font-weight-light;
      min-width: 200px;
      text-align: left;
    }
  }

  tbody {
    tr:nth-child(odd) {
      background-color: $color--gray-10;
    }
  }

  td,
  th {
    padding: 10px;
  }
}

.ExUi_cn--asset-details-body {
  overflow: auto;
  position: relative;

  > * {
    > section {
      margin-bottom: pxToRem(30);

      .title {
        font-size: pxToRem(16);
        font-weight: $font-weight-regular;
        margin-bottom: pxToRem(15);

        span {
          font-size: pxToRem(14);
          font-weight: $font-weight-light;
          margin-left: pxToRem(10);
        }
      }

      .body {
        padding-left: pxToRem(10);
      }

      > section {
        margin-bottom: pxToRem(30);
      }
    }
  }

  .copy-value {
    span {
      margin-right: pxToRem(10);
    }
  }

  .download-value {
    button {
      background: transparent;
      border: 0;
      color: $color--text-link;
      cursor: pointer;
      font-size: pxToRem(12);
      padding: 0;
    }
  }

  .ip-addresses {
    h2 {
      margin-top: 0;
    }
  }
}

.ExUi_asset-details-body-title {
  left: 20px;
  position: absolute;
  top: 20px;
}

.ExUI_asset-details-section-caption {
  font-size: 13px;
  margin-top: -16px;
}

.ExUI-asset-details-highlight {
  font-weight: $font-weight-medium;
  margin-top: 16px;
}
