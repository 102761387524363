@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi {
  &_profile-management-email {
    color: $color--text-1;
    font-family: $font-family-primary-proportional;
    font-size: 16px;
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin: 20px 0;
  }
}
