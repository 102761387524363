@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
$vertical-margin: 16px;

/* stylelint-disable */
.ExUi {
  &_column-facet-search {
    margin: $vertical-margin 0 20px 0;
    padding-right: 20px;

    input[type='text'] {
      border-radius: 3px;
    }

    .ExUi_search-bar {
      width: 280px;
    }
  }
}
/* stylelint-enable */
