@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
/* stylelint-disable */
.ExUi {
  &_btn {
    align-items: center;
    appearance: none;
    border: 0;
    border-radius: $radius--button;
    box-shadow: 0 0 0 0 transparent;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    flex-flow: row nowrap;
    font-family: $font-family-primary-proportional;
    font-size: $font-size-m;
    font-weight: $font-weight-semibold;
    justify-content: center;
    line-height: 1.2em;
    margin-right: $component-spacing-xxs;
    max-height: 32px;
    position: relative;
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out,
      box-shadow 0.5s ease-in-out;
    user-select: none;

    &--primary {
      background: $color--btn-primary--bg;
      color: $color--btn-primary--text;
      max-height: 32px;
      min-height: 32px;

      &:hover,
      &:active,
      &:focus {
        background-color: $color--btn-primary--hover;
      }
      &.mod--is-disabled,
      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
        // Without this, the tooltip does not recognize hover-off
        // https://developer.mozilla.org/en-US/docs/Web/CSS/pointer-events
        pointer-events: none;
      }
    }

    &--secondary {
      background: $color--btn-secondary--bg;
      border: 1px solid $color--btn-secondary--border;
      color: $color--btn-secondary--text;
      max-height: 32px;
      min-height: 32px;

      &:hover,
      &:active,
      &:focus {
        background: $color--blue-10;
        border: 1px solid $color--btn-secondary--border-hover;
        color: $color--blue-70;
      }

      &.mod--no-border {
        border: none;
      }
      &.mod--is-disabled,
      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
        // Without this, the tooltip does not recognize hover-off
        // https://developer.mozilla.org/en-US/docs/Web/CSS/pointer-events
        pointer-events: none;
      }
    }

    &--outlined-muted {
      background: $color--btn-outline-muted--bg;
      border: 1px solid $color--btn-outline-muted--border;
      color: $color--btn-outline-muted--text;

      &:hover,
      &:active,
      &:focus {
        border: 1px solid $color--btn-outline-muted--border-hover;
      }
    }

    &--outlined {
      background: $color--bg--button--l2;
      border: 1px solid $color--border--button--l2;
      color: $color--text--button--l2;

      &:hover,
      &:active,
      &:focus {
        background-color: $color--btn-destructive--hover;
        box-shadow: none;
      }
    }

    &--text,
    &--link {
      background-color: $color--btn-link--bg;
      border: none;
      box-shadow: none;
      color: $color--blue-60;

      &:hover,
      &:active,
      &:focus {
        background: $color--blue-10;
        box-shadow: none;
        color: $color--blue-70;
      }
      &.mod--is-disabled,
      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
        // Without this, the tooltip does not recognize hover-off
        // https://developer.mozilla.org/en-US/docs/Web/CSS/pointer-events
        pointer-events: none;
      }
    }

    &--on-dark {
      background-color: transparent;
      border: 1px solid hsla(209, 21%, 45%, 1);
      color: $color--text-5;
      font-weight: $font-weight-semibold;

      &:hover {
        background-color: hsla(209, 100%, 100%, 0.05);
        box-shadow: 0 0 4px 2px hsla(210, 95%, 55%, 0.25);
      }

      &.mod--no-border {
        border: none;

        &:hover {
          background-color: transparent;
          box-shadow: none;
        }
      }
    }

    &--contained {
      background: $color--bg--button--l1;
      color: $color--text--button--l1;

      &:hover {
      }
    }

    &--tiny {
      padding: 0 $component-spacing-min 0 0;
    }

    &--small {
      padding: $component-spacing-min $component-spacing-xxs;
    }

    &--medium {
      padding: $component-spacing-xxs 12px;
    }

    &--large {
      padding: 12px $component-spacing-m;
    }

    &__icn-left {
      display: inline-block;
      margin-left: -4px;
      margin-right: $component-spacing-min;
    }

    // Clear icon to fill space necessary to align check icon
    &__icn-left.clear {
      height: 16px;
      width: 16px;
    }

    &__icn-right {
      display: inline-block;
      margin-left: $component-spacing-min;
      margin-right: -4px;

      svg {
        display: block;
        fill: $color--icn--button--disabled;
      }
    }

    &--with-link {
      color: $color--text-link;
    }
  }
}

.ExUi_btn--outlined.ExUi_btn--with-link {
  border: 1px solid $color--active-link-border;

  &:hover {
    border: 1px solid $color--text-link;
  }

  &:active {
    border: 1px solid $color--text-link;
  }
}
