@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background-color: $color--dashboards--map-overview--tooltip-bg;
  border-radius: 0;
}

.leaflet-popup-close-button {
  display: none;
}

.leaflet-popup-content {
  margin: 0;
}
