@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_btn {
  // TODO address styles precedence for .ExUi_btn https://qadium.atlassian.net/browse/ROCI-3929
  // nested under .ExUi_btn to bump css precedence
  // stylelint-disable-next-line
  &.ExUi_TenantSwitcherDropdown__button {
    background-color: $color--steel-blue-60;
    border: 0;
    color: $color--white;
    font-size: 13px;
    font-weight: $font-weight-medium;
    justify-content: space-between;
    max-width: 200px;
    min-width: 160px;
    padding: 7px 15px;
    text-overflow: ellipsis;
    white-space: nowrap;

    // stylelint-disable-next-line
    &:hover {
      background-color: $color--gray-60;
    }

    // updating icon svg color
    // stylelint-disable-next-line
    svg {
      fill: #fff;
    }
  }

  // following style of ExUi_btn
  // stylelint-disable-next-line
  &__label {
    overflow: hidden;
  }
}

.ExUi_ {
  &TenantSwitcherDropdown {
    &__content {
      background-color: $color--white;
      border-radius: 4px;
      box-shadow: 0 4px 18px 0 rgba(155, 166, 187, 0.2);
      overflow: hidden;
      position: relative;
      top: 5px;
    }

    &__select {
      // target react-select input
      // stylelint-disable-next-line
      input {
        font-weight: normal;
      }

      // target react-select input
      // stylelint-disable-next-line
      input:focus {
        box-shadow: none;
      }

      &ClearIndicator {
        height: 18px;
        position: relative;
        top: 2px;
      }

      // stylelint-disable-next-line
      .facet-select__option__label {
        vertical-align: middle;
      }

      &LoadingMessage {
        align-items: center;
        display: flex;
        justify-content: center;
        min-height: 140px;
        text-align: center;

        // overriding styles for loading indicator component
        // stylelint-disable-next-line
        .loading-indicator {
          width: 45px;
        }

        // updating icon svg size
        // stylelint-disable-next-line
        svg {
          max-width: 45px;
        }
      }

      &NoOptionsMessage {
        align-items: center;
        color: $color--steel-blue-70;
        display: flex;
        font-size: 12px;
        justify-content: center;
        margin: 10px;
        min-height: 140px;
        text-align: center;
      }

      &SearchIcon {
        margin-left: 5px;
        position: relative;

        // overriding styles for icon component
        // stylelint-disable-next-line
        .icn {
          cursor: default;
        }
      }
    }
  }
}
