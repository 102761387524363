@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi {
  &_collapsible-sub-row {
    display: flex;

    &__tag-container {
      margin-right: 14px;
      max-height: 14px;
      overflow: hidden;

      &.expanded {
        max-height: none;
        overflow: visible;
      }

      .tags {
        display: flex;
        flex-wrap: wrap;
      }
    }

    &__show-more {
      font-size: 11px;
      font-weight: $font-weight-medium;
      height: 16px;
      line-height: 14px;
      margin-right: 53px;
      text-align: left;
      white-space: nowrap;

      .ExUi_btn__label {
        width: 56px;
      }
    }
  }
}
