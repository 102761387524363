@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_ {
  &DashboardGraph {
    &__requiredPolicyError {
      font-size: $font-size-s;
      line-height: $line-height-s;
      &--link {
        color: $color--blue-sf;
        cursor: pointer;
        font-weight: $font-weight-medium;
      }
    }
  }
}

div {
  &.dashboard-graph--tile {
    height: 100%;
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &.dashboard-graph--title {
    min-height: 20px;
    margin-bottom: 5px;
    z-index: 1;

    span {
      margin: 0;
      font-size: pxToRem(15);
      font-weight: $font-weight-medium;
      color: $color--dashboards--chart-title-gray;
      &.blue-chart {
        color: white;
      }
    }
  }

  &.dashboard-graph--title-common {
    @extend .dashboard-graph--title;
    span {
      margin: 0;
      font-size: pxToRem(12.5);
      font-weight: $font-weight-medium;
      border-radius: 2px;
      line-height: 15px;
      padding: 4px 6px 4px 6px;
    }
  }

  &.dashboard-graph--title-bar-graph {
    @extend .dashboard-graph--title-common;
    margin-bottom: 30px;
  }

  &.dashboard-graph--title-bar-graph--less-margin {
    @extend .dashboard-graph--title-bar-graph;
    margin-bottom: 10px;
  }

  &.dashboard-graph--title-gray {
    span {
      color: $color--dashboards--chart-title-gray;
      background: $color--dashboards--chart-title-background-gray;
    }
  }

  &.dashboard-graph--title-blue {
    span {
      color: $color--dashboards--chart-title-blue;
      background: $color--dashboards--chart-title-background-blue;
    }
  }

  &.dashboard-graph--title-red {
    span {
      color: $color--dashboards--chart-title-red;
      background: $color--dashboards--chart-title-background-red;
    }
  }

  &.dashboard-graph--title-light-gray {
    span {
      color: $color--dashboards--chart-title-light-gray;
      background-color: $color--dashboards--chart-title-background-light-gray;
    }
  }

  &.dashboard-graph--title-table {
    @extend .dashboard-graph--title;
  }

  &.dashboard-graph--subtitle {
    @extend .dashboard-graph--title;
    margin-top: 20px;
  }

  &.dashboards-graph--title-underlined {
    border-bottom: 1px solid #e3e7eb;
    margin: -21px -50px 0px -25px;
    padding: 12px 25px;
    position: relative;
    font-weight: $font-weight-medium;
    flex: 0 1 25px;
  }

  &.dashboards-graph--title-underlined-extra-bottom-space {
    @extend .dashboards-graph--title-underlined;
    margin-bottom: 15px;
  }

  &.dashboard-graph {
    flex: 1 1 auto;
    margin: auto;
    width: 100%;
    height: 100%;
    min-height: 0;
  }

  &.dashboard-radar-chart {
    @extend .dashboard-graph;
    top: 20px;
    position: relative;
  }

  &.vega-embed {
    width: 100%;
    height: 100%;
    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  &.dashboard-graph-no-data-container {
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    p.dashboard-no-prisma {
      text-align: center;
    }

    span.dashboard-no-prisma-header {
      color: #323843;
      font-size: 14pt;
      font-weight: $font-weight-medium;
      line-height: 20pt;
    }

    span.dashboard-no-prisma-main {
      color: #475a6c;
      font-size: 12pt;
      line-height: 20pt;
    }

    a.dashboard-no-prisma-link {
      color: #1157b0;
      font-size: 12pt;
      font-weight: $font-weight-medium;
    }

    div {
      background-color: white;
      padding: 20px 15px 20px 15px;
      box-shadow: 0 0 5px $color--dashboards--chart-no-data-box-shadow;
      border-radius: 5px;
      width: 50%;
      border: thin solid $color--dashboards--chart-no-data-border;

      h3 {
        margin: 0 0 5px 0;
        color: $color--dashboards--chart-no-data-h3-dark;
        font-weight: normal;
      }

      p {
        margin: 0;
        color: $color--dashboards--chart-no-data-p-light;
      }
    }
  }

  &.dashboard-graph-no-data-container--map {
    @extend .dashboard-graph-no-data-container;
    position: relative;
    top: -100%;
    z-index: 999;

    div {
      width: 350px;
      background: #030a0d;
      border: 1px solid #020508;
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
      color: white;

      span {
        margin-right: 5px;
      }

      h3 {
        color: #f8f9fc;
      }

      p {
        color: #f8f9fc;
        line-height: 18px;

        a {
          color: #3391ff;
        }
      }
    }
  }
}
