@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_form-select {
  max-height: 32px;

  > div {
    min-height: 32px;
  }

  input,
  input[type='text'] {
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }
}
