@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi {
  &_cell {
    &__item {
      align-items: center;
      color: hsla(219, 15%, 23%, 1);
      font-size: 14px;
      font-weight: $font-weight-medium;
      line-height: 1;

      &.icn {
        margin-right: 4px;
      }
    }

    &__item--link {
      cursor: pointer;
    }

    &.read-only {
      cursor: not-allowed;

      .icn {
        cursor: not-allowed;
      }
    }
  }
}
