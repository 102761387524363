@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
@import '../../../assets/styles/variables.scss';

.ExUi {
  &_config-column-footer {
    border-top: 1px solid $color-config-column-border;
    padding: 15px 20px;
    text-align: right;

    .ExUi_btn {
      &:last-of-type {
        margin-right: 0;
      }
    }

    &__save-check-box {
      align-items: center;
      display: flex;
      float: left;
      font-weight: $font-weight-regular;
      margin-top: 5px;

      input[type='checkbox'] {
        margin-right: 12px;
      }
    }
  }
}
