@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.csv-button-tooltip {
  max-width: pxToRem(150);
  text-align: center;
}

.file-download-dropdown-item-label {
  padding: pxToRem(8) pxToRem(10);
}

.file-download-wrapper {
  &.file-download-wrapper--disabled {
    background-color: hsla(214, 21%, 94%, 1);
    color: hsla(0, 0%, 75%, 1);
    cursor: not-allowed;

    button {
      cursor: not-allowed;
    }
  }
}

.ExUi_ {
  &FileDownloadButton {
    &__acrgStateToastMessage {
      display: inline-flex;

      &--success {
        position: relative;
        top: 10px;
      }
    }

    &__toastWarning {
      padding-right: 10px;
    }

    &__acrgSuccessMessage {
      color: $color--gray-90;
      font-size: 14px;
    }

    &__acrgReportName {
      color: $color--blue-60;
      font-size: 10px;
      padding-right: 15px;
    }

    &__healthyIcon {
      bottom: 5px;
      position: relative;
    }

    &__link {
      color: $color--blue-sf;
      font-size: 12px;
      padding-left: 5px;
      position: relative;

      &--download {
        bottom: 2px;
      }

      &--retry {
        bottom: 0;
      }
    }
  }
}
