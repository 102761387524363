@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.more-value {
  button {
    background-color: transparent;
    border: 0;
    color: $color--blue-60;
    cursor: pointer;
    font-size: 1rem;
    outline: none;
    padding-left: 0.35714rem;
  }
}
