@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_ {
  &BehaviorList {
    background: $color-bg-body;
    height: 100%;
    width: 100%;

    &__title {
      color: $color--text-2;
      font-size: 24px;
      font-weight: $font-weight-light;
      padding-right: 1rem;
    }
  }
}
