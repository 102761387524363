@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.settings-container {
  background-color: #f8f9fc;
  display: flex;
  min-height: 100%;

  .sidebar {
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 2px 4px 2px rgba(248, 248, 248, 0.76);
    margin: 20px;
    min-width: 250px;
    padding: 20px 10px 20px 10px;
    position: sticky;
    top: 20px;
    height: fit-content;

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      align-content: center;
      border: 1px solid transparent;
      border-radius: 5px;
      color: #1157b0;
      display: flex;
      flex-flow: column nowrap;
      font-family: $font-family-primary-proportional;
      font-size: 16px;
      height: 30px;
      justify-content: center;
      list-style: none;
      padding: 10px;

      button {
        background-color: inherit;
        border: 0;
        color: inherit;
        cursor: inherit;
        font-family: inherit;
        font-size: 100%;
        font-weight: inherit;
        padding: 0;
        text-align: left;
      }

      &:hover {
        border: 1px solid #0070ff;
        cursor: pointer;
      }
    }
  }

  .active {
    font-weight: $font-weight-medium;
  }

  .content {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 2px 4px 2px rgba(248, 248, 248, 0.76);
    flex-grow: 1;
    height: fit-content;
    margin: 20px 20px 20px 0;
  }

  a {
    color: $color--text-link;
    text-decoration: none;
  }
}
