@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.spinner-modal-dialog {
  /* A centered div above the overlay with a box shadow. */
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: hsla(214, 30%, 14%, 1);
  opacity: 1;

  .spinner-modal-dialog-content {
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    place-content: center;

    .loading {
      margin-top: 20px;
    }

    img {
      &.lock-end {
        filter: brightness(200%);
        -webkit-filter: brightness(200%);
      }

      &.explode {
        animation: explode 0.2s linear;
      }

      @keyframes explode {
        100% {
          -webkit-transform: scale(3); /* Safari */
          transform: scale(3);
          top: calc(50vh - 385px);
          left: calc(150vw - 385px);
          opacity: 0;
        }
      }

      @keyframes brighten-on-lock {
        0% {
          filter: brightness(100%);
          -webkit-filter: brightness(100%);
        }
        100% {
          filter: brightness(200%);
          -webkit-filter: brightness(200%);
        }
      }

      @keyframes rotate-cw {
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

      @keyframes rotate-cw-2 {
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

      @keyframes rotate-ccw {
        0% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
        100% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
      }
    }
  }
}
