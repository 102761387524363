@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
@import '~vega-tooltip/vega-tooltip';

[data-tooltip] {
  position: relative;
  z-index: 999;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + 5px); /* 5px is the size of the arrow */
  pointer-events: none;
  transition: 0.1s;
  will-change: transform;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
  @extend #vg-tooltip-element;
  @extend #vg-tooltip-element, .dark-theme;
  content: attr(data-tooltip);
  font-weight: $font-weight-light;
  border: none;
  min-width: 25px;
  max-width: var(--data-tooltip-width, 140px);
  width: max-content;
  width: -moz-max-content;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
  text-align: left;
  white-space: pre-wrap;
  word-break: break-word;
  transform: translate(-50%, -7px) scale(0.5);
  padding: var(--data-tooltip-padding, 8px);
  line-height: var(--data-tooltip-line-height, 13px);
}

/* Tooltip arrow */
[data-tooltip]:after {
  content: '';
  border-style: solid;
  border-width: 7px 7px 0 7px;
  border-color: rgba(32, 32, 32, 0.9) transparent transparent transparent;
  transition-duration: 0s;
  transform-origin: top;
  transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
  transition-delay: 0.1s;
  transform: translate(-50%, -7px) scale(1);
}
/*
  Arrow slide down effect only on mouseenter (NOT on mouseleave)
*/
[data-tooltip]:hover:after {
  transition-delay: 0.2s; /* Starting after the grow effect */
  transition-duration: 0.2s;
  transform: translateX(-50%) scaleY(1);
}

/* LEFT */
/* Tooltip + arrow */
[data-tooltip-location='left']:before,
[data-tooltip-location='left']:after {
  left: auto;
  right: calc(100% + 5px);
  bottom: 50%;
}

/* Tooltip */
[data-tooltip-location='left']:before {
  transform: translate(-7px, 50%) scale(0.5);
}
[data-tooltip-location='left']:hover:before {
  transform: translate(-7px, 50%) scale(1);
}

/* Arrow */
[data-tooltip-location='left']:after {
  border-width: 7px 0 7px 7px;
  border-color: transparent transparent transparent rgba(32, 32, 32, 0.9);
  transform-origin: left;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location='left']:hover:after {
  transform: translateY(50%) scaleX(1.5);
}

/* RIGHT */
[data-tooltip-location='right']:before,
[data-tooltip-location='right']:after {
  left: calc(100% + 5px);
  bottom: 50%;
}

[data-tooltip-location='right']:before {
  transform: translate(20%, 10%) scale(0.5);
}
[data-tooltip-location='right']:hover:before {
  transform: translate(7px, 50%) scale(1);
}

[data-tooltip-location='right']:after {
  border-width: 7px 7px 7px 0;
  border-color: transparent rgba(32, 32, 32, 0.9) transparent transparent;
  transform-origin: right;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location='right']:hover:after {
  transform: translateY(50%) scaleX(1);
}

/* BOTTOM */
[data-tooltip-location='bottom']:before,
[data-tooltip-location='bottom']:after {
  top: calc(100% + 5px);
  bottom: auto;
}

[data-tooltip-location='bottom']:before {
  transform: translate(-50%, 7px) scale(0.5);
}
[data-tooltip-location='bottom']:hover:before {
  transform: translate(-50%, 7px) scale(1);
}

[data-tooltip-location='bottom']:after {
  border-width: 0 7px 7px 7px;
  border-color: transparent transparent rgba(32, 32, 32, 0.9) transparent;
  transform-origin: bottom;
}
