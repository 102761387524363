@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.dashboard-table {
  border-collapse: collapse;
  overflow-x: auto;
  width: 100%;
  table-layout: fixed;

  tbody > tr:last-child > th,
  tbody > tr:last-child > td {
    border-bottom: none;
  }
}

.dashboard-table-data-cell {
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-bottom-color: #ececec;
  padding: pxToRem(14) pxToRem(14) pxToRem(14) pxToRem(14);
  color: #323843;
  font-weight: $font-weight-regular;
  text-align: center;
  font-size: pxToRem(14);
  width: 19%;

  .max-width-content {
    max-width: 100%;
    width: fit-content;

    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .max-width-content:hover {
    z-index: 99999;
  }

  .align-right {
    text-align: right;
  }
}

.dashboard-table-data-cell-chip-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.dashboard-table-data-cell-chip {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 3px 4px 3px 8px;
  margin: 0px 4px;
  flex-grow: 1;
  background: #f8f9fc;
  border-radius: 2px;
}

.chip {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 3px 4px 3px 8px;

  background: #f8f9fc;
  border-radius: 2px;

  position: static;
  width: 72px;
  height: 24px;
  left: 16px;
  top: 12px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
}

.chip-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: static;
  left: 40px;
  top: 2px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
}

.chip-label-zero {
  @extend .chip-label;
  color: #cbd2da;
}

.chip-icon {
  position: static;
  width: 16px;
  height: 16px;
  left: 52px;
  top: 4px;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 4px;
}

.chip-icon-grayed-out {
  @extend .chip-icon;
  filter: grayscale(100%) opacity(20%);
}

.dashboard-table-sparkline-count {
  width: 3rem;
  display: inline-block;
  vertical-align: 100%;
  text-align: right;
}

.dashboard-table-flex-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px;

  position: static;
  width: 519px;
  height: 48px;
  left: 344px;
  top: 0px;

  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 0px;

  white-space: nowrap;
}

.dashboard-table-title-row-cell {
  @extend .dashboard-table-data-cell;
  font-family: $font-family-primary-proportional;
  font-size: pxToRem(14);
  font-weight: $font-weight-medium;
  padding: pxToRem(14);

  &.small-font {
    font-size: pxToRem(12);
  }
}

.dashboard-table-sparkline-cell {
  @extend .dashboard-table-data-cell;
  width: 30%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.dashboard-table-data-cell-redtext {
  color: #7d1515;
}

.dashboard-table-data-cell-greentext {
  color: #00856c;
}

.dashboard-table-data-cell-bluetext {
  color: #3967a8;
}

.dashboard-table-data-cell-greytext {
  color: #475a6c;
}

.dashboard-table-data-cell-bluetext-bolder {
  color: #3967a8;
  font-weight: $font-weight-medium;
}

.dashboard-table-data-cell-transparent {
  color: rgba(1, 1, 1, 0);
}

.dashboard-table-data-cell-percentage {
  width: 50px;
  display: inline-block;
}

.dashboard-table-title-divider {
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-bottom-color: #ececec;
  position: absolute;
  right: 0;
  width: 100%;
}

.dashboard-table-wrapper {
  margin-top: 10px;
  padding-top: 10px;
}
