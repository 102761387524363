// stylelint-disable plugin/selector-bem-pattern
@mixin scrollbar {
  &::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb:vertical,
  &::-webkit-scrollbar-thumb:horizontal {
    background: $color-full-column-config-scroll-bg;
    border-radius: calc($scroll-bar-width / 2);
  }

  &::-webkit-scrollbar-thumb:vertical:hover,
  &::-webkit-scrollbar-thumb:horizontal:hover {
    background: $color-scroll-thumb-hover;
  }
}
