@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.cn--meta-nav-panel {
  @include user-select;
  align-items: center;
  display: inline-flex;
  padding: 0 10px 0 10px;
  box-sizing: border-box;
  height: $size-nav-menu-bar-height;

  span {
    display: inline-flex;
    align-items: center;
  }
  .icn {
    margin-left: $size-nav-menu-meta-panel-icon-spacing;
    &:hover {
      opacity: 0.75;
    }
  }
  .icn--help {
    margin-left: 0;
  }
  .active {
    background-color: $color-nav-menu-item-bg-active;
    height: 100%;
    margin-left: 4px;

    .icn {
      margin-left: 0;
    }
  }
}
