@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
$height: pxToRem(12);

.cn--ellipsis-tooltip-text-overflow-container {
  span {
    display: inline-block;
    max-width: calc(100% - 25px);
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }
}

.ellipsis-tooltip {
  background-color: $color--ellipsis-tooltip--bg;
  border-radius: pxToRem(3);
  color: $color--tooltip--text;
  cursor: default;
  display: inline-block;
  height: $height;
  line-height: $height;
  margin: auto pxToRem(5);
  vertical-align: middle;

  .cn--ellipsis {
    color: $color--ellipsis-dots--text;
    display: inline-block;
    font-size: pxToRem(20);
    font-weight: $font-weight-bold;
    letter-spacing: pxToRem(1);
    padding: pxToRem(1) pxToRem(2);
  }

  .cn--ellipsis-tooltip-item {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .ellipsis-tooltip-item {
    font-size: pxToRem(12);
    line-height: 1.333333rem;
  }
}
