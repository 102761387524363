@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_mini-table {
  &__pagination-details {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  &__pagination-count {
    margin-right: 5px;
  }

  &__pagination-row {
    background-color: white;
  }

  .pagination-cell {
    padding-top: 0;
  }
}
