/* ================= expander colors ================== */

//region Approved DS Palette Colors By Name
// Don't use these colors inside any other file, instead use them to set the values of semantic color variables below
// to use inside other files or components

// Main Palette //
// white
$color--white: hsla(225, 0%, 100%, 1);
// end white

//Black
$color--black: hsla(0, 0%, 0%, 1);
$color--black-shadow: hsla(0, 0%, 0%, 0.2);
//end Black

// grays
$color--gray-100: hsla(0, 0%, 10%, 1);
$color--gray-90: hsla(0, 0%, 20%, 1);
$color--gray-80: hsla(0, 0%, 28%, 1);
$color--gray-70: hsla(0, 0%, 34%, 1);
$color--gray-60: hsla(0, 0%, 44%, 1);
$color--gray-50: hsla(0, 0%, 57%, 1);
$color--gray-40: hsla(0, 0%, 72%, 1);
$color--gray-30: hsla(180, 1%, 86%, 1);
$color--gray-20: hsla(180, 2%, 92%, 1);
$color--gray-10: hsla(180, 5%, 96%, 1);
$color--gray-05: hsla(0, 0%, 98%, 1);
$color--border--section-divider: $color--gray-30;
// end grays
// blues
$color--blue-100: hsla(215, 100%, 10%, 1);
$color--blue-90: hsla(215, 100%, 18%, 1);
$color--blue-80: hsla(213, 100%, 23%, 1);
$color--blue-70: hsl(211, 100%, 31%);
$color--blue-60: hsla(207, 100%, 40%, 1);
$color--blue-50: hsla(207, 90%, 51%, 1);
$color--blue-40: hsla(207, 100%, 61%, 1);
$color--blue-30: hsla(207, 100%, 76%, 1);
$color--blue-20: hsla(207, 88%, 90%, 1);
$color--blue-10: hsla(206, 100%, 97%, 1);

// Steel Blue
$color--steel-blue-100: hsla(210, 35%, 7%, 1);
$color--steel-blue-90: hsla(214, 30%, 14%, 1);
$color--steel-blue-80: hsla(213, 21%, 26%, 1);
$color--steel-blue-70: hsla(213, 18%, 34%, 1);
$color--steel-blue-60: hsla(213, 13%, 42%, 1);
$color--steel-blue-50: hsla(212, 12%, 52%, 1);
$color--steel-blue-40: hsla(213, 13%, 65%, 1);
$color--steel-blue-30: hsla(213, 16%, 76%, 1);
$color--steel-blue-20: hsla(210, 23%, 90%, 1);
$color--steel-blue-10: hsla(206, 30%, 95%, 1);

// blue main variables
$color--blue-sf: $color--blue-60;
$color--blue-oakland: $color--blue-70;
$color--blue-hover: $color--blue-20;
$color--blue-active: $color--blue-10;
$color--border-panel: $color--blue-10;
$color--text-link: $color--blue-sf;
$color--active-link-border: $color--gray-30;
$color--blue-primary: $color--blue-sf;
$color--blue-primary-hover: $color--blue-oakland;
$color--text-select-list-item-active: $color--blue-oakland;
$color--bg-btn-toggle-active: $color--blue-10;
// end blues

// Purples
$color--purple-100: hsla(254, 75%, 15%, 1);
$color--purple-90: hsla(254, 82%, 20%, 1);
$color--purple-80: hsla(254, 69%, 28%, 1);
$color--purple-70: hsla(254, 62%, 33%, 1);
$color--purple-60: hsla(254, 49%, 54%, 1);
$color--purple-50: hsla(254, 82%, 72%, 1);
$color--purple-40: hsla(254, 77%, 81%, 1);
$color--purple-30: hsla(254, 100%, 89%, 1);
$color--purple-20: hsla(254, 72%, 93%, 1);
$color--purple-10: hsla(259, 71%, 96%, 1);
// end purples

// Reds
$color--red-100: hsla(0, 100%, 10%, 1);
$color--red-90: hsla(0, 100%, 16%, 1);
$color--red-80: hsla(0, 100%, 21%, 1);
$color--red-70: hsla(354, 100%, 28%, 1);
$color--red-60: hsla(0, 76%, 38%, 1);
$color--red-50: hsla(0, 62%, 53%, 1);
$color--red-40: hsla(0, 67%, 61%, 1);
$color--red-30: hsla(0, 94%, 79%, 1);
$color--red-20: hsla(0, 92%, 90%, 1);
$color--red-10: hsla(0, 100%, 97%, 1);
// end reds

// Oranges
$color--orange-100: hsla(33, 100%, 10%, 1);
$color--orange-90: hsla(33, 100%, 14%, 1);
$color--orange-80: hsla(33, 100%, 20%, 1);
$color--orange-70: hsla(34, 100%, 25%, 1);
$color--orange-60: hsla(35, 100%, 29%, 1);
$color--orange-50: hsla(36, 100%, 41%, 1);
$color--orange-40: hsla(38, 100%, 47%, 1);
$color--orange-30: hsla(38, 96%, 69%, 1);
$color--orange-20: hsla(38, 97%, 86%, 1);
$color--orange-10: hsla(37, 100%, 94%, 1);
// end oranges

// Yellows
$color--yellow-100: hsla(42, 100%, 9%, 1);
$color--yellow-90: hsla(43, 100%, 14%, 1);
$color--yellow-80: hsla(43, 100%, 19%, 1);
$color--yellow-70: hsla(44, 97%, 24%, 1);
$color--yellow-60: hsla(44, 97%, 29%, 1);
$color--yellow-50: hsla(45, 98%, 39%, 1);
$color--yellow-40: hsla(46, 99%, 48%, 1);
$color--yellow-30: hsla(47, 100%, 64%, 1);
$color--yellow-20: hsla(49, 100%, 78%, 1);
$color--yellow-10: hsla(47, 100%, 92%, 1);
// end yellows

// Greens
$color--green-100: hsla(140, 100%, 9%, 1);
$color--green-90: hsla(139, 100%, 14%, 1);
$color--green-80: hsla(139, 100%, 18%, 1);
$color--green-70: hsla(139, 100%, 22%, 1);
$color--green-60: hsla(139, 97%, 26%, 1);
$color--green-50: hsla(137, 81%, 34%, 1);
$color--green-40: hsla(136, 73%, 42%, 1);
$color--green-30: hsla(120, 57%, 63%, 1);
$color--green-20: hsla(101, 62%, 85%, 1);
$color--green-10: hsla(95, 73%, 94%, 1);
// end green

// Indigos
$color--indigo-100: hsla(231, 73%, 16%, 1);
$color--indigo-90: hsla(231, 61%, 27%, 1);
$color--indigo-80: hsla(231, 55%, 35%, 1);
$color--indigo-70: hsla(231, 60%, 44%, 1);
$color--indigo-60: hsla(225, 88%, 57%, 1);
$color--indigo-50: hsla(225, 100%, 63%, 1);
$color--indigo-40: hsla(225, 100%, 79%, 1);
$color--indigo-30: hsla(225, 100%, 87%, 1);
$color--indigo-20: hsla(224, 100%, 92%, 1);
$color--indigo-10: hsla(224, 71%, 96%, 1);
// end indigos

/* =============== end expander colors ================ */

/* =============== icon fills ================ */
$color--icn-dark: hsla(228, 47%, 23%, 1);

/* =============== end icon fills ================ */
