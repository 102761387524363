@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
div {
  &.sparkline-graph--tile {
    height: 50%;
    width: 30%;
    display: flex;
    flex-flow: column;
    margin: 0;
    align-content: center;
  }

  &.sparkline-graph {
    margin: 0;
    width: 50%;
    height: 50%;
    display: inline-block;
    position: relative;
    top: -7px;
  }

  &.vega-embed {
    width: 100%;
    height: 100%;
    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  &.sparkline-graph-no-data-container {
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    div {
      background-color: white;
      padding: 20px 15px 20px 15px;
      box-shadow: 0 0 5px $color--dashboards--chart-no-data-box-shadow;
      border-radius: 5px;
      width: 50%;
      border: thin solid $color--dashboards--chart-no-data-border;

      h3 {
        margin: 0 0 5px 0;
        color: $color--dashboards--chart-no-data-h3-dark;
        font-weight: normal;
      }

      p {
        margin: 0;
        color: $color--dashboards--chart-no-data-p-light;
      }
    }
  }
}
