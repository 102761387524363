@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
/* stylelint-disable */
.filter-bar__container {
  border-bottom: 1px solid $color--border--panel;
}

.filter-bar {
  background-color: $color--bg--filter-bar;
  display: flex;
  font-family: $font-family-primary-proportional;
  min-height: 69px;
  padding-right: 24px;
  padding-left: 24px;
  width: auto;
  z-index: 5;

  &:first-of-type {
    padding-top: 16px;
  }

  &:last-child {
    padding-bottom: 16px;
  }

  > button {
    margin-top: auto;
  }

  .filter-bar__reset-tooltip {
    margin-left: auto;
    margin-top: auto;
  }

  .reset-btn {
    line-height: 18px;
    margin-bottom: 7px;
    margin-left: auto;
    margin-right: 0;

    &.ExUi_btn--primary {
      line-height: 17px;
      margin-bottom: 8px;
    }

    .cn--tooltip {
      left: 15px;
    }
  }

  .customize-btn {
    padding: 4px;
  }

  .filter-bar-facet {
    display: inline-block;
    max-height: 69px;
  }

  .filter-bar-facet-item {
    display: inline-block;
    margin-right: 10px;

    &.facet-footer {
      display: flex;
      justify-content: space-between;
    }
  }

  .filter-bar-facet-item-label {
    color: $color--filter-bar--label;
    display: table-cell;
    font-size: 12px;
    height: 25px;
    line-height: 18px;
    padding-bottom: 4px;
    vertical-align: bottom;
  }

  .filter-bar__config-btn-tooltip {
    align-self: flex-end;
    display: flex;
    height: 100%;
  }

  .filter-bar-config-btn {
    margin-bottom: 8px;
    min-width: 90px;
  }
}
