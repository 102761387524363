@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
$header-height: pxToRem(38);

.details {
  background-color: hsla(0, 0%, 100%, 1);
  height: 100%;
  overflow: auto;
  position: relative;

  .loading,
  .server-error,
  .not-found {
    background-color: hsla(0, 0%, 100%, 1);
    color: $color--text-3;
    height: 100%;
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;

    a {
      display: inline-block;
      font-size: pxToRem(12);
      padding-top: pxToRem(40);
    }
  }

  .loading {
    &.spinner-parent.mod--spinner-parent--is-spinner-showing--true {
      overflow: visible;
    }

    .cn--spinner .spinner {
      background-color: transparent;

      svg g {
        fill: hsla(218, 8%, 77%, 1);
      }
    }
  }
}

.cn--details-body {
  height: calc(100% - #{$header-height});
  overflow: auto;
  position: relative;

  > * {
    > section {
      margin-bottom: pxToRem(30);

      .title {
        margin-bottom: pxToRem(15);
        font-size: pxToRem(16);
        font-weight: $font-weight-regular;

        span {
          font-size: pxToRem(14);
          font-weight: $font-weight-light;
          margin-left: pxToRem(10);
        }
      }

      .body {
        padding-left: pxToRem(10);
      }
    }
  }

  .copy-inputs {
    position: absolute;
    right: 100%;
  }

  .copy-value {
    span {
      margin-right: pxToRem(10);
    }
  }

  .download-value {
    button {
      background: transparent;
      border: 0;
      color: $color--text-link;
      cursor: pointer;
      font-size: pxToRem(12);
      padding: 0;
    }
  }
}
