@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.digests-configuration-container {
  padding: 40px 20px 20px 40px;

  .digests-configuration-container-title {
    height: 21px;
    font-size: 18px;
    font-weight: $font-weight-medium;
    letter-spacing: 0;
    line-height: 21px;
  }

  .digests-configuration-box-item {
    margin-top: 30px;

    .digests-configuration-check-box {
      float: left;
      height: 15px;
      width: 15px;

      input {
        vertical-align: top;
        margin: 1px;
      }

      input[type='checkbox'] {
        box-shadow: inset 0 0 0 2px $color--text-3;
      }

      input[type='checkbox']:checked {
        background-color: #0070ff;
        box-shadow: none;
      }
    }

    .digests-configuration-text {
      margin-left: 30px;

      .digests-configuration-headline-text {
        height: 18px;
        color: $color--text-1;
        font-size: 16px;
        font-family: $font-family-primary-proportional;
        letter-spacing: 0;
        line-height: 18px;
      }

      .digests-configuration-help-text {
        margin-top: 6px;
        height: 28px;
        color: $color--text-3;
        font-family: $font-family-primary-proportional;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
      }
    }
  }

  .filter-bar-facet-item {
    margin: 10px 0 20px 30px;

    &-label {
      color: #5a738b;
      margin: 0 0 5px;
      line-height: 14px;
      font-size: 12px;
    }
  }
}
