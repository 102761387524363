@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

// for loading spinning on legacy ui index.html
.ExUi_LoadingBackground {
  background: #0d1e2b;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: -1;

  &__spinner {
    animation: spin 1s linear infinite;
    background: #0d1e2b;
    border-radius: 50%;
    height: 130px;
    left: calc(50% - 65px);
    position: fixed;
    top: calc(50% - 65px);
    width: 130px;
  }

  &__spinner::before,
  &__spinner::after {
    content: '';
    position: absolute;
  }

  &__spinner::before {
    background: linear-gradient(
          0deg,
          hsla(219, 19%, 67%, 1) 50%,
          hsla(219, 19%, 67%, 0.7) 100%
        )
        0% 0%,
      linear-gradient(
          90deg,
          hsla(219, 19%, 67%, 0.7) 0%,
          hsla(219, 19%, 67%, 0.4) 100%
        )
        100% 0%,
      linear-gradient(
          180deg,
          hsla(219, 19%, 67%, 0.4) 0%,
          hsla(219, 19%, 67%, 0.1) 100%
        )
        100% 100%,
      linear-gradient(
          360deg,
          hsla(219, 19%, 67%, 0.1) 0%,
          hsla(219, 19%, 67%, 0) 100%
        )
        0% 100%;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    border-radius: 50%;
    bottom: -1px;
    left: -1px;
    right: -1px;
    top: -1px;
  }

  &__spinner::after {
    background: #0d1e2b;
    border-radius: 50%;
    bottom: 8px;
    left: 8px;
    right: 8px;
    top: 8px;
  }
}
