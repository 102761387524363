@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
//stylelint-disable
@import '../../../assets/styles/legacy/buttons';

.cn-keyset-pagination {
  display: flex;
  justify-content: flex-end;

  &-with-actions {
    align-items: center;
    justify-content: space-between;
    padding: $layout-component-spacing-x-small $layout-component-spacing-medium;

    .btn {
      min-height: 32px;

      &.btn-with-icon {
        display: flex;
        flex-direction: row;
        padding: $component-spacing-min $component-spacing-xs;
        place-items: center;
      }

      .icn--export {
        background-position: -335px -212px;
      }

      &.file-dropdown-btn {
        display: inline-block;
        min-width: 94px;
      }
    }

    .cn--tooltip,
    .table-filter-select {
      &:hover {
        z-index: 10;
      }
    }

    .dropdown-content {
      z-index: 10;
    }

    .right-action-btn {
      margin-left: auto;
    }

    .keyset-pagination {
      margin: 0 0 0 7px;
    }

    .left-actions {
      align-items: center;
      display: flex;
    }
  }
}

.keyset-pagination {
  align-items: center;
  background: $color-bg-body;
  display: inline-flex;
  justify-content: space-between;
  margin: $layout-component-spacing-x-small $component-spacing-xxs;
  z-index: 1;

  .cn--pagination-details,
  .cn--pagination-input {
    color: $color-keyset-pagination-text;
    font-size: pxToRem(13);
    font-weight: $font-weight-bold;
    margin-right: 8px;

    .pagination-details--light {
      font-weight: $font-weight-regular;
    }
  }

  .cn--pagination-details {
    align-items: center;
    display: flex;
    flex: 0.5;
    margin-left: 8px;
    justify-content: center;
    white-space: pre;
  }

  // -btn is used internally by the react-table Pagination component, so just
  // have it extend quill's btn classes
  .ExUi_pagination--default-btn {
    &:first-child {
      margin-right: 6px;
    }

    &:last-child {
      margin-left: 6px;
    }
  }

  .ExUi_pagination--default-btn[disabled] {
    @extend .mod--btn--disabled;
  }

  .cn--pagination-btn {
    align-items: center;
    display: flex;
    flex: 0.5;
    justify-content: center;
    margin: 0 0 0 $component-spacing-xs;
    text-align: center;
  }

  .cn--pagination-input {
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #DADBDB;
    border-radius: 4px;
    display: flex;
    flex: 1.5;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 0;
    padding: 8px 10px;
    text-align: center;
  }

  .pagination-input--page-info {
    display: inline-block;
    margin: pxToRem(3) 0;
    white-space: nowrap;
  }

  .pagination-input--page-jump {
    display: inline-block;

    input {
      width: pxToRem(70);
    }
  }

  .pagination-input--page-size-options {
    margin: 0;

    select {
      appearance: none;

      background-image: linear-gradient(
          45deg,
          transparent 70%,
          $color--gray-90 50%
        ),
        linear-gradient(
          135deg,
          $color--gray-90 30%,
          transparent 0
        );
      background-position: calc(100% - 5.5px) 6px, calc(100%) 6px,
        calc(100% - 0.5em) 0.5em;
      background-repeat: no-repeat;
      background-size: 8px 5px, 6px 6px, 1.5em 1.5em;
      border: 0;
      box-shadow: none;
      box-sizing: border-box;
      color: $color--gray-90;
      font-size: 13px;
      font-weight: $font-weight-medium;

      // https://stackoverflow.com/questions/22153080/how-do-i-change-the-select-box-arrow/27451020
      margin: 0;
      min-width: 66px;
      outline: none;
      padding: 0 14px 0 0;
    }
  }
}

.ExUi_pagination--default-btn {
  background-color: transparent;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
