@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
div .ip-addresses {
  height: calc(100% - #{2 * 20px});
  margin-bottom: pxToRem(30);
  overflow: auto;
  position: relative;

  h2 {
    span {
      font-size: pxToRem(14);
      font-weight: $font-weight-light;
      margin-left: pxToRem(10);
    }
  }

  .subtitle {
    color: #bcc4d2;
    font-size: 11px;
    margin-bottom: 22px;
  }

  .body {
    padding-left: pxToRem(10);
  }

  thead {
    text-align: left;

    th {
      font-weight: $font-weight-medium;
      padding-bottom: pxToRem(15);
    }
  }

  td {
    padding-bottom: pxToRem(10);
    padding-right: pxToRem(30);
  }
}
