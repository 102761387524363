@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_view {
  .annotations-section {
    min-width: 363px;
    h2 {
      color: $color--gray-100;
    }
  }
}

.annotations {
  font-size: $font-size-m;

  .annotations-content {
    .title {
      color: $color--gray-80;
      margin: $component-spacing-l 0 $component-spacing-min;
      svg {
        display: inline-block;
        vertical-align: bottom;
        margin: $component-spacing-min $component-spacing-min 0 0;
      }
    }
    .annotations-tags {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4px;
      justify-content: space-around;
      max-width: fit-content;
    }
    .existing-annotations-tags {
      .title {
        margin: $component-spacing-min 0 $component-spacing-min;
      }
    }
  }

  .annotations-actions {
    background-color: $color--gray-05;
    display: flex;
    justify-content: flex-end;
    margin-top: $component-spacing-m;
    padding: $component-spacing-l;

    > * {
      margin-left: $component-spacing-xxs;
    }

    button.btn {
      font-weight: $font-weight-light;
      padding: $component-spacing-xxs $component-spacing-xs;

      &:disabled {
        background-color: $color--btn-txt-disabled !important; // override status button default
        color: $color--white;
        cursor: not-allowed;

        &:hover {
          box-shadow: none;
        }
      }
    }
  }

  .annotations-contacts {
    .add-contact-btn {
      cursor: pointer;
      height: 20px;
      border: none;
      background: none;
      padding: 0;
      width: 20px;
    }

    .annotations-new-contact-form {
      display: block;

      .form-actions {
        display: flex;
        justify-content: flex-end;
        margin-top: $component-spacing-xs;

        button.btn {
          font-weight: $font-weight-light;
          margin-left: $component-spacing-xxs;
          padding: $component-spacing-xxs $component-spacing-xs;
        }
      }

      .form-section {
        font-size: $font-size-s;
        margin-bottom: $component-spacing-xs;

        > * {
          display: inline-block;
          vertical-align: top;
        }

        $label-min-width: 105px;

        input {
          border: 0;
          border-bottom: 1px solid $color--gray-30;
          box-shadow: none;
          outline: none;
          padding: 0 $component-spacing-min $component-spacing-min 0;
          width: 100%;
        }

        label {
          min-width: $label-min-width;
        }

        .cn--input {
          width: calc(100% - #{$label-min-width});
        }

        .form-info {
          &.contact-exists {
            font-weight: $font-weight-regular;
          }
        }

        .form-error {
          color: $color--error;
          font-size: $font-size-xs;
        }

        .form-message {
          margin-top: $component-spacing-min;
        }
      }
    }

    .cn--annotations-new-contact-form {
      padding: $component-spacing-xs;
      text-align: center;

      > * {
        text-align: left;
      }
    }

    .cn--contact-cards {
      .title {
        margin: $component-spacing-m 0 $component-spacing-min;
      }
      .contact-card-container {
        display: grid;
        grid-template-columns: 164px 164px 164px;
        gap: $component-spacing-min $component-spacing-xxs;
      }
    }

    .contact-card {
      border: 1px solid $color--gray-30;
      border-radius: 5px;
      box-shadow: $shadow-02;
      display: block;
      justify-content: space-between;
      position: relative;
      text-overflow: ellipsis;
      vertical-align: top;
      word-break: break-word;
      white-space: nowrap;
      min-width: 164px;

      &:hover {
        .remove-contact {
          display: block;
        }
      }

      .basic-info {
        padding: $component-spacing-xxs $component-spacing-xs;
        min-height: 124px;
      }

      .footer {
        border-top: 1px solid $color--gray-30;
        bottom: 0;
        min-height: 54px;
        position: absolute;
        width: 100%;

        .footer-container {
          font-size: $font-size-xs;
          line-height: $line-height-xs;
          padding: $component-spacing-xxs $component-spacing-xs;
          white-space: pre-wrap;

          a {
            display: block;
          }
        }
      }

      .name {
        font-size: $font-size-l;
        font-weight: $font-weight-regular;
      }

      .remove-contact {
        background-color: transparent;
        border: 0;
        cursor: pointer;
        display: none;
        padding: 0;
        position: absolute;
        right: 7px;
        top: 7px;

        div {
          margin-top: -3px;
        }
      }

      .role {
        font-size: $font-size-s;
      }
    }
  }

  .annotations-notes {
    position: relative;

    &:hover {
      button {
        display: block;
      }
    }

    button {
      border-radius: 50%;
      bottom: 13px;
      cursor: pointer;
      background: none;
      display: none;
      border: none;
      font-size: $font-size-l;
      height: 20px;
      padding: 0;
      position: absolute;
      right: 23px;
      width: 20px;

      > div {
        margin-top: -2px;
      }
    }

    textarea {
      background-color: $color--white;
      border: 1px solid $color--gray-30;
      border-radius: 4px;
      box-shadow: none;
      font-size: $font-size-m;
      height: 80px;
      outline: none;
      padding: $component-spacing-xxs;
      resize: none;
      width: 100%;
    }
  }

  .pill {
    background-color: $color-pill-list-bg;
    border-radius: 2px;
    color: $color-pill-list-text;
    display: inline-block;
    font-weight: $font-weight-medium;
    margin-bottom: 3px;
    margin-right: 3px;
    padding: $component-spacing-min $component-spacing-xxs;
    position: relative;

    &:hover {
      background-color: $color-pill-list-bg-hover;
      padding-right: $component-spacing-m;

      .remove-pill {
        display: inline-block;
      }
    }

    .remove-pill {
      cursor: pointer;
      display: none;
      position: absolute;
      right: 10px;
    }
  }
}

.annotations-select {
  input {
    box-shadow: none !important;
    font-size: $font-size-m;
  }

  .annotations-contact-option-content {
    cursor: pointer;

    > * {
      display: inline-block;
      margin-right: $component-spacing-xxs;
      vertical-align: bottom;

      &:last-child {
        margin-right: 0;
      }

      &.icn {
        margin-right: $component-spacing-xs;
      }
    }

    .contact-full-name {
      font-size: $font-size-m;
      font-weight: $font-weight-regular;
    }

    .contact-role {
      font-size: $font-size-s;
    }
  }

  .annotations-tag-input {
    font-size: $font-size-l;
    line-height: $line-height-l;
  }

  .annotations-tag-multi-value-remove {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin-top: $component-spacing-min;
    padding: 0;
    text-align: center;
    width: 14px;

    span {
      position: relative;
      left: 0.5px;
      top: -2px;
    }
  }
}

.annotations-select-input {
  .disclaimer {
    color: $color--gray-50;
    font-size: $font-size-s;
    margin: $component-spacing-min 0 $component-spacing-xs;
  }

  .error-message {
    color: $color--error;
    font-size: $font-size-s;
    margin-top: $component-spacing-xxs;
  }
}
