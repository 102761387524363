@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.cn--menu-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: $color-nav-menu-bar-bg;
  min-height: $size-nav-menu-bar-height;
  width: 100vw;
  position: fixed;
  z-index: 99;

  .cn--logo {
    justify-self: center;
    align-self: center;
    display: inline-block;
    flex: 0 1 232px;
    .icn--expanse-logo,
    .icn--expanse-logo-rebrand {
      margin-left: 15px;
    }
  }
  .cn--menu-bar-prime {
    display: inline-block;
    flex: 1 10 auto;
  }
  .cn--menu-bar-sub {
    display: inline-block;
    flex: 0 1 auto;
    justify-self: end;
    align-self: center;
  }

  .cn--menu-bar-instance {
    font-size: 13px;
    display: flex;
    align-self: center;
    color: $color-nav-menu-tenant-text;
  }

  &.hidden {
    position: relative;
    visibility: hidden;
  }
}

@media (max-width: 740px) {
  .cn--menu-bar {
    min-height: 2 * $size-nav-menu-bar-height;
  }
}
