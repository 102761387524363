@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
.ExUi_ {
  &AssociatedAsset {
    &__pocHeader {
      font-weight: normal;
    }

    & + & {
      &::before {
        background: rgba(183, 191, 206, 0.28);
        content: '';
        display: block;
        height: 1px;
        margin: $size__padding__module__interior
          (-$size__padding__module__interior);
        width: calc(100% + (#{$size__padding__module__interior} * 2));
      }
    }

    &__assocTitle {
      font-size: 12px;
      font-weight: $font-weight-regular;
      margin-bottom: 10px;
    }

    &__assocAsset {
      font-size: 16px;
      font-weight: $font-weight-medium;
      line-height: 21px;
      margin: 4px 0 6px;
      padding-bottom: 0;
    }

    &__attributionReasons {
      font-weight: $font-weight-regular;
      margin: 10px 0 20px;
    }

    &__notes {
      font-weight: $font-weight-regular;
    }

    &__ownershipIcon {
      margin: 0 8px 0 0;
    }

    &__ownershipTitle {
      font-size: 17px;
      margin: 0 0 20px;
    }

    &__recentIps {
      margin: 10px 0 30px;
    }

    &__referenceReason {
      color: $color-associated-asset-reference-reason-text;
      font-size: 12px;
      font-weight: $font-weight-regular;
      line-height: 16px;
    }

    &__toggleDetails {
      margin: 10px 0 30px 164px;
    }
    /* stylelint-disable-next-line */
    .data-list-item-key {
      width: 154px;
    }
  }
}
