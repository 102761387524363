@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
$margin-left: 10px;

.bulk-annotations-form {
  min-width: 500px;
  padding: 20px 30px 30px;

  section {
    margin-bottom: 30px;

    .title {
      color: #323843;
      font-size: 13px;
      font-weight: $font-weight-medium;
    }
  }

  .disclaimer {
    color: #bcc4d2;
    font-size: 12px;
    margin-top: 10px;
  }

  .ExUi_btn--primary {
    font-size: 13px;
    font-weight: $font-weight-light;
    min-height: 18px;
    padding: 3px 10px;
  }

  .common-annotation-values {
    margin-top: 15px;
  }

  .form-actions {
    display: flex;
    justify-content: flex-end;
  }

  .small-pill {
    background-color: #f7f7f7;
    border: 0;
    border-radius: 2px;
    box-shadow: none;
    color: #2069c5;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: $font-weight-light;
    margin-bottom: 4px;
    margin-right: 4px;
    padding: 2px 12px;
    vertical-align: top;

    &:last-child {
      margin-right: 0;
    }
  }
}

.bulk-annotations-tabs {
  background-color: $color--white;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 4px 0;
  position: absolute;

  .tab-label {
    border-bottom: 3px solid transparent;
    color: #4e5355;
    padding: 8px 30px 12px;
    position: relative;
    top: 3px;

    &.active {
      border-bottom: 3px solid #1157b0;
      font-weight: $font-weight-semibold;
    }
  }

  .tabs-labels {
    border-bottom: 3px solid #e6e6e6;
    white-space: nowrap;
  }
}

.bulk-annotations-form-footer {
  background: white;
  border-top: solid hsla(212, 17%, 83%, 1) 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;

  .right-most-button {
    margin: 0;
  }
}
