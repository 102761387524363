$available-columns-width: 1025px;
$active-columns-width: 300px;
$content-margin-left: 20px;
$content-margin-top: 18px;

.ExUi {
  &_fully-config-column-modal {
    background-color: white;
    box-shadow: 0 2px 4px 0 hsla(200, 20%, 10%, 0.2);
    display: flex;
    flex-direction: column;
    height: 80vh;
    left: 0;
    margin: 0 auto;
    overflow: hidden;
    position: fixed;
    right: 0;
    width: $available-columns-width + $active-columns-width;
    z-index: 5;

    @media (max-width: 1366px) {
      width: 80vw;
    }

    &__content {
      display: flex;
      flex: 1 1 0;
      // min-height is needed to override the flexbox default of
      // `min-height: auto` so that the content will shrink dynamically
      min-height: 0;
    }

    .icn--settings-btn {
      height: 15px;
    }
  }
}
