.expander-version-string {
  position: fixed;
  font-size: 0.8em;
  color: $color--text-link;
  bottom: 0;
  left: 5px;
  z-index: 10000000000;

  a {
    color: inherit;
  }
}
