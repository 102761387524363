@import "expander-ui/assets/styles/variables.scss"; @import "expander-ui/assets/styles/mixins.scss";@import "expander-ui/assets/styles/design-system/colors"; @import "expander-ui/assets/styles/design-system/fonts";
// stylelint-disable plugin/selector-bem-pattern
.ExUi {
  &_PaginationControls {
    margin-top: 32px;
  }

  &_panel_table {
    border-collapse: collapse;
    margin-top: 32px;
    min-width: 100%;
    table-layout: fixed;

    thead {
      border-bottom: #e3e7eb solid 2px;
    }

    th {
      font-weight: $font-weight-medium;
    }

    td {
      font-weight: $font-weight-regular;
    }

    th,
    td {
      color: $color--gray-90;
      font-family: $font-family-primary-proportional;
      font-size: 13px;
      padding: 5px 0 5px 10px;
      text-align: left;
      word-break: break-word;
    }

    &_link {
      display: flex;
      justify-content: flex-end;
      padding: 30px 0 0;
    }

    .opt {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      pointer-events: none;

      .icn {
        margin-right: 6px;
      }

      .opt-text {
        display: inline-block;
      }
    }

    &__subRowToggleHeader {
      width: 28px;
    }
  }

  &_PaginationControls + &_panel_table {
    margin-top: 0;
  }
}
